<template>
	<div class="container">
		<div class="row my-3">
			<div class="col-12">
				<h1 v-if="checkoutStore.vm.pageTitle">{{ checkoutStore.vm.pageTitle }}</h1>
			</div>
			<div class="col-lg-8 col-xl-9">
				<CheckoutSteps />
				<Message v-if="vm.paymentFailed" :closable="false" severity="error">
					{{ paymentFailedMessage }}
				</Message>
				<Message v-if="!vm.allowPlaceOrder" :closable="false" severity="warn">
					{{ $t("checkout.message.orderNotAllowed") }}
				</Message>
				<router-view />
			</div>
			<div v-if="!isSmallScreen" class="col-lg-4 col-xl-3">
				<Card class="checkout-summary">
					<template #title>
						{{ $t("checkout.titleSummary") }}
					</template>
					<template #content>
						<PriceSummary :vm="priceSummary" />
					</template>
					<template #footer>
						<Button
							class="p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1"
							:disabled="!canGoToStep(activeStep + 1)"
							:label="$t(`checkout.button.goToStep${activeStep === -1 ? 2 : activeStep + 2}`)"
							@click="goToNextStep()"
						/>
						<small v-if="isOverviewStep">
							<i18n-t keypath="checkout.lblAgreeWithTerms">
								<template #link>
									<a :href="checkoutStore.vm.termsAndConditionsLink" target="_blank">{{
										$t("common.termsAndConditions")
									}}</a>
								</template>
							</i18n-t>
						</small>
					</template>
				</Card>
			</div>
		</div>
	</div>
	<CartPageSummary
		v-if="isSmallScreen"
		:button-text="$t(`checkout.button.goToStep${activeStep === -1 ? 2 : activeStep + 2}`)"
		:can-submit="canGoToStep(activeStep + 1)"
		:show-terms="isOverviewStep"
		:total-price="priceSummary?.totalPrice"
		@clickAction="goToNextStep()"
	>
		<template #priceSummary>
			<PriceSummary :vm="priceSummary" />
		</template>
	</CartPageSummary>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartPageSummary from "@/components/commerce/cart/cartPageSummary.vue";
import CheckoutSteps, { CheckoutStep } from "@/components/commerce/checkout/checkoutSteps.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import Steps from "primevue/steps";
import { BuckarooStatus } from "@/types/enum/buckarooStatus";
import { CartClient } from "@/types/models/cart/cartClient";
import { CheckoutForm } from "@/types/models/form/checkoutForm";
import { Component, Prop } from "vue-facing-decorator";
import { CouponCode } from "@/types/generated/Api/couponCode";
import { ICheckout } from "@/types/viewModels/commerce/checkoutViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PaymentMethod } from "@/types/enum/paymentMethod";
import { PropType } from "vue";
import { first, sum } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";
import { GaEvent } from "@/types/models/googleAnalytics/enum/gaEvent";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { CheckoutSteps, Steps, PriceSummary, CartPageSummary } })
export default class Checkout extends BaseComponent {
	@Prop({ type: Object as PropType<ICheckout>, required: true, default: {} }) vm!: ICheckout;

	cartStore = useCartStore();
	checkoutStore = useCheckoutStore();

	created(): void {
		if (!this.vm.checkoutForm.billingAddressForm) {
			this.vm.checkoutForm.billingAddressForm = { ...this.vm.checkoutForm.companyForm };
		}
		this.checkoutStore.vm = this.vm;
		this.cartStore.cartClient = this.vm.cartClient;
		this.checkoutStore.isDetailStepValid = this.vm.hasPreviouslyOrdered;
		Log.info("Checkout", this.vm);
	}

	get cartClient(): CartClient {
		return this.cartStore.cartClient?.itemCount ? this.cartStore.cartClient : this.checkoutStore.vm.cartClient;
	}

	get activeStep(): CheckoutStep {
		return this.checkoutStore.activeStep;
	}

	get isOverviewStep(): boolean {
		return this.activeStep === CheckoutStep.Overview;
	}

	get priceSummary(): IPriceSummary {
		let {
			appliedCouponCodes,
			totalBasePrice,
			subtotalWithoutVat,
			personalDiscount,
			totalPrice,
			totalInstallationPrice,
			vatPercentage,
			vatAmount,
		} = this.cartClient;
		const { creditCardSurtaxPercentage, vatSituation } = this.checkoutStore.vm;

		const couponDiscount = sum(appliedCouponCodes?.map((x) => x.discount));
		const discountLines = new Map<string, number>();
		appliedCouponCodes?.forEach((coupon: CouponCode) => {
			const name = coupon.isCadacWallet ? this.$t("cart.lblCadacWallet") : coupon.name;
			discountLines.set(name, coupon.discount);
		});
		const creditCardSurchargePercentage = creditCardSurtaxPercentage;
		const creditCardSurchargeAmount =
			this.checkoutStore.vm.checkoutForm.selectedPaymentMethod === PaymentMethod.creditcard
				? creditCardSurchargePercentage
					? (totalPrice * creditCardSurchargePercentage) / 100
					: 0
				: 0;

		if (creditCardSurchargeAmount) totalPrice += creditCardSurchargeAmount;
		return {
			totalBasePrice,
			discountLines: discountLines,
			totalCouponDiscount: couponDiscount,
			totalDiscount: personalDiscount + couponDiscount,
			totalPersonalPrice: subtotalWithoutVat,
			totalInstallPrice: totalInstallationPrice,
			totalPrice,
			vatPercentage,
			vatSituation,
			vatAmount,
			creditCardSurchargeAmount,
			creditCardSurchargePercentage,
		} as IPriceSummary;
	}

	get paymentFailedMessage(): string {
		switch (this.vm.paymentStatus) {
			case BuckarooStatus.REJECTED:
			case BuckarooStatus.CANCELLED:
				return this.$t("checkout.message.paymentCancelled");
			default:
				return this.$t("checkout.message.paymentFailed");
		}
	}

	goToNextStep(): void {
		const nextStep = this.activeStep + 1;
		if (nextStep === CheckoutStep.Confirmation) {
			this.completePurchase();
		} else if (this.canGoToStep(nextStep)) {
			this.trackCheckoutStep(this.activeStep);
			this.checkoutStore.activeStep = nextStep;
			const stepsEl = document.querySelector(".checkout-steps");
			window.scrollTo(0, stepsEl?.getBoundingClientRect().bottom || 80);
		}
	}

	canGoToStep(step: CheckoutStep): boolean {
		return this.checkoutStore.canGoToStep(step);
	}

	private trimCheckoutModel(): CheckoutForm {
		const checkoutModel = this.checkoutStore.vm.checkoutForm;
		//Reset payment provider for payment methods where it's not needed, else the buckaroo payment will fail.
		if (checkoutModel.selectedPaymentMethod === PaymentMethod.creditcard) {
			checkoutModel.paymentMethod = checkoutModel.selectedPaymentProvider;
			checkoutModel.selectedPaymentProvider = "";
		} else if (checkoutModel.selectedPaymentMethod !== PaymentMethod.ideal) {
			checkoutModel.selectedPaymentProvider = "";
		}
		return checkoutModel;
	}

	private completePurchase(): void {
		this.loadingStore.increaseLoadingCount();
		console.log(" this.trimCheckoutModel()", this.trimCheckoutModel());
		this.axios
			.post(`/api/${this.layoutStore.vm.localizationPrefix}/checkout/complete/`, this.trimCheckoutModel())
			.then((res) => {
				this.trackPurchase();
				this.openUrl(res.data);
			})
			.catch((err) => {
				Log.error(err);
				this.loadingStore.decreaseLoadingCount();
				this.$toast.add({
					severity: "error",
					detail: this.$t("checkout.toast.completePurchaseError"),
					life: 3000,
				});
			});
	}

	private trackCheckoutStep(step: number): void {
		let orderDataDataLayer;
		let checkoutDataLayer;
		const eventStep = (): GaEvent => {
			switch (step) {
				case CheckoutStep.Details:
					orderDataDataLayer = {
						coupon: first(this.cartClient.appliedCouponCodes?.map((x) => x.name)) || null,
					};
					checkoutDataLayer = this.constructCheckoutData(1, "add_shipping_info", 4);
					return GaEvent.AddShippingInfo;
				case CheckoutStep.PaymentMethod:
					orderDataDataLayer = {
						payment_method: PaymentMethod[this.vm.checkoutForm.selectedPaymentMethod],
						coupon: first(this.cartClient.appliedCouponCodes?.map((x) => x.name)) || null,
					};
					checkoutDataLayer = this.constructCheckoutData(2, "add_payment_info", 4);

					return GaEvent.AddPaymentInfo;
				default:
					return GaEvent.Undefined;
			}
		};
		if (eventStep()) {
			const eCommerceDataLayer = this.constructECommerceDataLayer(
				eventStep(),
				toFixed(this.priceSummary.totalPersonalPrice, 2),
				this.cartStore.allCartLineClients.map((item) => ({
					...item,
					name: item.title,
					priceSubTotal: item.priceSubTotal / (item.amount || 1),
					priceBase: item.priceSubTotal / (item.amount || 1),
					discount: item.discount / (item.amount || 1),
				})),
				null
			);

			this.setDataLayer.checkoutStepEvents(eCommerceDataLayer, checkoutDataLayer, orderDataDataLayer);
		}
	}

	private trackPurchase(): void {
		const eCommerceDataLayer = this.constructECommerceDataLayer(
			GaEvent.Purchase,
			toFixed(this.priceSummary.totalPersonalPrice, 2),
			this.cartStore.allCartLineClients.map((item) => ({
				...item,
				name: item.title,
				priceSubTotal: item.priceSubTotal / (item.amount || 1),
				priceBase: item.priceSubTotal / (item.amount || 1),
				discount: item.discount / (item.amount || 1),
			})),
			null
		);

		const hasUsedWallet = this.cartClient.appliedCouponCodes?.filter((x) => x.isCadacWallet)?.length;
		const currentDate = (): string => {
			const today = new Date();
			const yyyy = today.getFullYear();
			const mm = ("0" + (today.getMonth() + 1)).slice(-2);
			const dd = ("0" + today.getDate()).slice(-2);
			const currentDate = dd + "-" + mm + "-" + yyyy;
			return currentDate;
		};

		const orderDataDataLayer = {
			transaction_id: null,
			vat: toFixed(this.priceSummary.vatAmount, 2),
			coupon: this.toSnakeCase(first(this.cartClient.appliedCouponCodes?.map((x) => x.value))) || null,
			wallet_value_used: hasUsedWallet
				? toFixed(first(this.cartClient.appliedCouponCodes?.map((x) => x.discount)), 2)
				: null,
			currency: this.currentCurrency.toLowerCase(),
			payment_method: PaymentMethod[this.checkoutStore.vm.checkoutForm.selectedPaymentMethod],
			order_date: currentDate(),
			customer: this.userProfile ? this.constructCustomerData(this.userProfile) : null,
		};

		const persistingDatalayer = { eCommerceDataLayer, orderDataDataLayer };
		sessionStorage.setItem("purchase-datalayer", JSON.stringify(persistingDatalayer));
	}
}
</script>

<style scoped lang="scss">
.checkout-summary {
	position: sticky;
	top: 6rem;
	margin-bottom: 2rem;
}
</style>
