<template>
	<ProductLayout :vm="vm">
		<ProductPageContentSection v-if="$slots.blocks" :type="'media'">
			<slot name="blocks" />
		</ProductPageContentSection>
		<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
			<h3 class="">{{ $t("product.titleUpSell") }}</h3>
			<div class="row">
				<div v-for="product in vm.relatedUpSellProducts" :key="product" class="col-md-6">
					<ProductOverviewItemCard :item="product" layout="grid" />
				</div>
			</div>
		</ProductPageContentSection>
		<template #summary>
			<ProductPageSummary :is-product-available="false" :show-support-card="!isMobile">
				<Card class="product-config-cta-card">
					<template #content>
						<h3 class="mb-1">{{ vm.configurationTitle }}</h3>
						<p v-if="!isMobile" class="lead">
							{{ vm.configurationSubtitle }}
						</p>

						<LinkButton
							v-if="vm.formDetailPageUrl"
							class="p-button-success p-button-arrow p-button-raised mt-0 mt-sm-2"
							:label="vm.configurationCta"
							:url="vm.formDetailPageUrl"
						/>
					</template>
				</Card>
			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import ProductLayout from "../layout/productLayout.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
import ProductPageHeader from "@/components/product/productPageHeader.vue";
import ProductPageSummary from "@/components/product/productPageSummary.vue";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { useCartStore } from "@/store/commerce/cartStore";
import { IServiceProduct } from "@/types/viewModels/commerce/serviceProductViewModel";
import SupportCard from "@/components/commerce/supportCard.vue";
import LinkButton from "@/components/common/linkButton.vue";

@Component({
	components: {
		LinkButton,
		AutoComplete,
		PopoverIcon,
		PriceSummary,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationStep,
		ProductConfigurationSummary,
		ProductConfigurationSummaryItem,
		ProductLayout,
		ProductOverviewItemCard,
		ProductPageContentSection,
		ProductPageHeader,
		ProductPageSummary,
		ProgressSpinner,
		ScrollPanel,
		SupportCard,
	},
})
export default class ServiceProduct extends BaseComponent {
	@Prop({ type: Object as PropType<IServiceProduct>, required: true, default: {} }) vm!: IServiceProduct;

	submitted = false;
	cartStore = useCartStore();

	created(): void {
		Log.info("ServiceProduct", this.vm);
		this.initFreshChatTopic(["product"]);
		if (this.vm.viewModelIsValid) {
			// this.productStore.init();
		} else Log.error(new Error(this.vm.viewModelErrors?.join(", ")));
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Other,
			} as unknown as BaseProductItemViewModel,
			crossSellProducts: [],
		};
	}

	addServiceProductToCart(): void {
		this.submitted = true;
		if (!this.validateProductConfiguration()) {
			setTimeout(() => this.scrollToFirstValidationError(), 50);
			return;
		}
		// this.loadingStore.increaseLoadingCount();
		//
		// this.axios
		// 	.post(`/api/cart/add-support`, this.addSupportRequestModel)
		// 	.then(() => {
		// 		this.cartStore.showModal("ProductAdded");
		// 	})
		// 	.catch(() =>
		// 		this.$toast.add({
		// 			severity: "error",
		// 			summary: this.$t("common.messages.titleError"),
		// 			detail: this.$t("common.messages.addToCartError"),
		// 			life: 3000,
		// 		})
		// 	)
		// 	.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private validateProductConfiguration(): boolean {
		return false;
	}
}
</script>

<style scoped lang="scss">
.product-config-step-subtitle {
	font-size: 1rem;
	font-weight: 400;
	color: var(--text-color-secondary);
}

::v-deep(.product-config-cta-card) {
	margin-bottom: 1rem;
	background-color: var(--primary-color);
	color: var(--primary-color-text);

	.p-card-content {
		text-align: center;
	}

	@media (max-width: 576px) {
		.p-card-content {
			padding: 0;
		}
	}
}
</style>
