<template>
	<ProductOverviewLayout
		:vm="overviewVm"
		@on-filter-change="onFilterChange"
		@on-search="onSearch"
		@on-sort-change="onSortChange"
	>
		<template #header>
			<div class="pt-3 pb-2">
				<slot name="breadcrumbs" />
				<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
			</div>
		</template>
		<template #grid-item="{ data }">
			<div
				v-if="!isSpinnerVisible('product-overview')"
				class="mb-2"
				:class="data?.products?.length ? 'col-md-6' : 'col-md-6  col-xxl-3'"
			>
				<ProductOverviewItemCard :item="data" layout="grid" />
			</div>
			<ProductOverviewItemCardSkeleton v-else type="grid" />
		</template>
		<template #list-item="{ data }">
			<ProductOverviewItemCard
				v-if="!isSpinnerVisible('product-overview')"
				:item="data"
				layout="list"
				show-intro
			/>
			<ProductOverviewItemCardSkeleton v-else type="list" />
		</template>
	</ProductOverviewLayout>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ProductOverviewItemCardSkeleton from "@/components/product/productOverviewItemCardSkeleton.vue";
import ProductOverviewLayout, { IProductOverviewLayout } from "@/views/layout/productOverviewLayout.vue";
import { CollectionItemViewModel } from "@/types/models/software/collectionItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { ISoftwareProductOverview } from "@/types/viewModels/commerce/softwareProductOverviewViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { OverviewSortType } from "@/types/enum/overviewSortType";
import { PropType } from "vue";
import { SoftwareItemViewModel } from "@/types/models/software/softwareItemViewModel";
import { take } from "lodash";
import { useProductOverviewFilterStore } from "@/store/common/productOverviewFiltersStore";

@Component({
	components: {
		ProductOverviewItemCard,
		ProductOverviewItemCardSkeleton,
		ProductOverviewLayout,
	},
})
export default class SoftwareProductOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ISoftwareProductOverview>,
		required: true,
		default: {},
	})
	vm!: ISoftwareProductOverview;

	overviewItems: SoftwareItemViewModel[] | CollectionItemViewModel[] = [];

	filterStore = useProductOverviewFilterStore();

	collections: CollectionItemViewModel[] = [];

	created(): void {
		Log.info("SoftwareProductOverview", this.vm);
		this.initFreshChatTopic(["overview"]);
		this.overviewItems = this.vm.products;
		this.collections = this.vm.collections;
		this.addCollectionsToOverview();
		this.filterStore.init(this.vm.filters);
		this.filterStore.addPriceSortOptions();
	}

	get overviewVm(): IProductOverviewLayout {
		return { productType: ItemProductType.Software, items: this.overviewItems, defaultViewLayout: "grid" };
	}

	get maxAmountOfCollectionsToShow(): number {
		return this.isSmallScreen ? 2 : 3;
	}

	get collectionsToShow(): CollectionItemViewModel[] {
		return take(this.collections, this.maxAmountOfCollectionsToShow);
	}

	onSortChange(sortType: OverviewSortType): void {
		this.filterStore.sortType = sortType;
		this.updateFilters();
	}

	onFilterChange(): void {
		this.updateFilters();
	}

	onSearch(query: string): void {
		if (!this.filterStore.hasQueryFilter) return;
		this.filterStore.updateSearchQuery(query);
		this.updateFilters();
	}

	private updateFilters(): void {
		this.loadingStore.showSpinner("product-overview");
		this.loadingStore.increaseLoadingCount();
		this.filterStore
			.updateProductFilters()
			.then((data) => {
				this.collections = data?.collections ? [...data.collections] : [];
				this.overviewItems = data?.products ? [...data.products] : [];
				this.addCollectionsToOverview();
			})
			.catch((err) => Log.error(err))
			.finally(() => {
				this.loadingStore.hideSpinner("product-overview");
				this.loadingStore.decreaseLoadingCount();
			});
	}

	private addCollectionsToOverview(): void {
		this.overviewItems.splice(this.isSmallScreen ? 4 : 8, 0, ...this.collectionsToShow);
	}
}
</script>

<style lang="scss" scoped>
:deep(.product-grid-card) {
	&.p-card {
		min-height: 200px;
	}
}

:deep(.p-dataview .p-dataview-header) {
	top: var(--navbarHeightInPx);
}
</style>
