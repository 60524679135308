<template>
	<ProductLayout :vm="vm">
		<div v-if="selectableModules.length > 1" class="microservice-modules">
			<h2 class="mb-0">{{ $t("microservice.modules.title") }}</h2>
			<Message v-if="linesTotal() === 0"
					 :id="validation-msg-micro-service-product-atleast-one"
					 :closable="false"
					 severity="error">
				{{ $t("common.message.error.atLeastOne") }}
			</Message>
			<template v-for="item in selectableModules" :key="item.id">
				<div class="microservice-module">
					<div class="microservice-module-description">
						<strong>{{item.title}}</strong>
						<p><em>{{ $tc("microservice.modules.variation.duration", item.durationInMinutes) }}</em></p>
						<div class="microservice-module-description-content" v-html="item.description"></div>
					</div>
					<div class="microservice-module-price">
						{{formatCurrency(item.basePrice)}}
					</div>
					<div class="microservice-module-toggle">
						<InputSwitch v-model="item.checked" @change="onToggleSelectionClicked(item)" />
					</div>
				</div>
			</template>
		</div>

		<ProductPageContentSection type="media">
			<div class="content" v-html="vm.description"></div>
		</ProductPageContentSection>

		<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
			<h3 class="">{{ $t("product.titleUpSell") }}</h3>
			<div class="row">
				<div v-for="product in vm.relatedUpSellProducts" :key="product" class="col-md-6">
					<ProductOverviewItemCard :item="product" layout="grid" />
				</div>
			</div>
		</ProductPageContentSection>

		<template #summary>
			<ProductPageSummary :can-submit="!submitted && linesTotal() !== 0"
								:is-product-available="vm.viewModelIsValid"
								:total-price="priceSummary?.totalPrice"
								@addToCart="addMicroServiceProductToCart()" class="product-summary">
				<template #priceSummary>
					<h5 class="mb-1">
						{{ vm.title }}
					</h5>
					<p class="text-muted d-flex">
						<span class="price-label">
							{{ linesTotal() }} x {{ linesTotal() > 1 ? "Modules" : "Module" }}
						</span>
						<span class="flex-shrink-0">
							{{ formatCurrency(priceSummary.totalPrice) }}
						</span>
					</p>
					<hr class="my-1" />
					<PriceSummary :vm="priceSummary" />
					<p class="text-muted lead text-right">{{ $t("common.productSummary.allPricesExclVat") }}</p>
				</template>

				<template #validationMessage>
					<Message v-if="linesTotal() === 0"
							 :id="validation-msg-micro-service-product-atleast-one-sum"
							 :closable="false"
							 severity="error">
						{{ $t("common.message.error.atLeastOne") }}
					</Message>
				</template>
				<template #walletMessage>
					<WalletMessage v-show="!isSmallScreen && vm?.canUseWallet && parseInt(calculateWalletAmount()) !== 0"
								   :wallet-amount="calculateWalletAmount()" />
				</template>

			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import ProductLayout from "../layout/productLayout.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
import ProductPageHeader from "@/components/product/productPageHeader.vue";
import ProductPageSummary from "@/components/product/productPageSummary.vue";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";
import toFixed from "accounting-js/lib/toFixed.js";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import WalletMessage from "@/components/common/WalletMessage.vue";
import { useCartStore } from "@/store/commerce/cartStore";
import { IMircoServiceProduct } from "@/types/viewModels/commerce/microServiceProductViewModel";
import { IMircoServiceProductVariation } from "@/types/viewModels/commerce/microServiceProductVariationViewModel";
import SupportCard from "@/components/commerce/supportCard.vue";
import LinkButton from "@/components/common/linkButton.vue";
import { ProductCategoryType } from "@/types/enum/productCategoryType";

interface SelectedModule extends IMircoServiceProductVariation {
	checked: boolean;
}

@Component({
	components: {
		LinkButton,
		AutoComplete,
		PopoverIcon,
		PriceSummary,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationStep,
		ProductConfigurationSummary,
		ProductConfigurationSummaryItem,
		ProductLayout,
		ProductOverviewItemCard,
		ProductPageContentSection,
		ProductPageHeader,
		ProductPageSummary,
		ProgressSpinner,
		ScrollPanel,
		SupportCard,
        WalletMessage,
	},
})
export default class MicroServiceProduct extends BaseComponent {
    @Prop({ type: Object as PropType<IMircoServiceProduct>, required: true, default: {} }) vm!: IMircoServiceProduct;

    selectableModules: SelectedModule[] = [];

	submitted = false;
	cartStore = useCartStore();

	priceSummary!: IPriceSummary;


	created(): void {
		Log.info("ServiceProduct", this.vm);
		if (!this.vm.viewModelIsValid) {
            Log.error(new Error(this.vm.viewModelErrors?.join(", ")));
		} 

		// pre-select all modules
		this.vm.modules.forEach((item: IMircoServiceProductVariation) => {
			this.vm.preSelectedSkus.length ? 
            this.selectableModules.push({ checked: this.isPreSelectedModule(item.sku), ...item }) :
			this.selectableModules.push({ checked: true, ...item });
		})

		this.priceSummary = this.calculatePrice();

        this.setDataLayer.viewItem(
            toFixed(this.priceSummary.totalPrice, 2),
            [
                {
                    id: this.vm.id,
                    name: this.vm.title,
                    basePrice: this.vm.basePrice,
                    personalPrice: this.vm.basePrice,
                    productCategory: ProductCategoryType.ServiceProduct,
                    brand: "cadac",
                    amount: 1,
                },
            ]
		);
	}



	onToggleSelectionClicked(item: any) {
        this.selectableModules.map((module: SelectedModule) => {
			if (module.id === item.sku) {
                module.checked = !module.checked
			}

			return module;
		})

        this.priceSummary = this.calculatePrice();
	}

	isPreSelectedModule(sku: string): boolean {
		return this.vm.preSelectedSkus.includes(sku);
	}

	// Show cross-sell product in modal
	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Other,
			} as unknown as BaseProductItemViewModel,
            crossSellProducts: this.vm.relatedCrossSellProducts,
		};
	}
    
	addMicroServiceProductToCart(): void {
		this.submitted = true;

        const model = {
			oldLineItemIds: this.vm.oldLineItemIds,
            productId: this.vm.cadacIdentifier,
			selectedModules: this.productsToAdd.map(product => {
				return { Sku: product.sku }
			}),
		}

		this.loadingStore.increaseLoadingCount();
        
		 this.axios
             .post(`/api/cart/add-micro-service`, model)
		 	.then(() => {
		 		this.cartStore.showModal("ProductAdded");
		 	})
		 	.catch(() =>
		 		this.$toast.add({
		 			severity: "error",
		 			summary: this.$t("common.messages.titleError"),
		 			detail: this.$t("common.messages.addToCartError"),
		 			life: 3000,
		 		})
		 	)
			 .finally(() => {
                 this.setDataLayer.addToCart(
                     toFixed(this.priceSummary.totalPrice, 2),
                     [
                         {
                             id: this.vm.id,
                             name: this.vm.title,
                             basePrice: this.priceSummary.totalPrice,
                             personalPrice: this.priceSummary.totalPrice,
                             productCategory: ProductCategoryType.ServiceProduct,
                             brand: "cadac",
                             amount: 1,
                         },
                     ]
                 );

				 this.loadingStore.decreaseLoadingCount();
				 this.submitted = false;				 
			 });        
	}

    get totalPrice(): number {
        return this.productsToAdd.reduce((prev, cur) => {
            return prev + (cur.basePrice || 0)
        }, 0);
	}

    calculateWalletAmount(): number {
        return this.productsToAdd.reduce((prev, cur) => {
            return prev + (cur.walletAmount || 0)
        }, 0);
    }


    calculatePrice(): IPriceSummary {
        return { totalPrice: this.totalPrice };
    }

	get productsToAdd(): IMircoServiceProductVariation[] {
        return this.selectableModules.filter((x) => x.checked);
	}

    linesTotal(): number {
        return this.productsToAdd.length;
    }
    
}
</script>

<style lang="scss">
    .microservice-modules {
        background: #f5f5f5;
        padding: 2rem;
        margin: 2rem 0 0 0.9375rem;

        @media (min-width: 992px) {
            margin: 2rem 0 2rem 0.9375rem;
        }
    }

    .microservice-module {
        padding: 2rem 0 1rem;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-flow: row wrap;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
	

    .microservice-module div {
		@media (min-width: 556px) {
			padding: 0 1rem 0 0;
		}

        &:last-child {
            padding: 0;
        }
    }

    .microservice-module-description {
        width: 100%;
        flex: initial;
		order: 1;

        @media (min-width: 556px) {
            flex: 5;
			width: auto;
        }
    }

    .microservice-module-description-content {
        ul {
            list-style: disc;
            list-style-position: inside;
        }
    }

    .microservice-module-line {
        border: 1px solid #eee;
        height: 0;
        width: 100%;
        display: block;
        margin-top: 2rem;
        margin-bottom: 0;
    }


    .microservice-module-toggle,
    .microservice-module-price {
        display: flex;

		@media (min-width: 556px) {
			justify-content: end;
		}
    }

    .microservice-module-toggle {
        order: 2;

        @media (min-width: 556px) {
            flex: 1;
            order: 3;
        }
    }

    .microservice-module-price {
        flex: 1;
        font-size: 1.1rem;
        font-weight: 400;
        order: 3;
        margin-left: 1rem;

        @media (min-width: 556px) {
            order: 2;
            margin-left: 0;
        }
    }

    .product-summary {
        .price-label {
            flex: 1;
        }
    }
    

</style>
