import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-6 mb-2" }
const _hoisted_3 = { class: "mb-1" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: 0,
  class: "col-12 col-lg-6 mb-2"
}
const _hoisted_11 = { class: "mb-1" }
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["textContent"]
const _hoisted_17 = { class: "col-12 col-lg-6 mb-2" }
const _hoisted_18 = { class: "mb-1" }
const _hoisted_19 = ["textContent"]
const _hoisted_20 = ["textContent"]
const _hoisted_21 = { class: "col-12 col-lg-6 mb-2" }
const _hoisted_22 = { class: "mb-1" }
const _hoisted_23 = { class: "d-flex align-items-center" }
const _hoisted_24 = {
  class: "mr-1",
  height: "67",
  width: "67"
}
const _hoisted_25 = ["xlink:href"]
const _hoisted_26 = ["textContent"]
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-12 col-lg-6 mb-2" }
const _hoisted_29 = { class: "col-12 col-lg-6 mb-2" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = {
  key: 0,
  class: "col-12 mb-2"
}
const _hoisted_32 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CartOverview = _resolveComponent("CartOverview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Panel, { toggleable: false }, {
          header: _withCtx(() => [
            _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("checkout.titleYourCompany")), 1),
            _createVNode(_component_Button, {
              class: "p-button-raised p-button-text p-button-rounded ml-auto",
              icon: "pi pi-pencil",
              label: _ctx.isSmallScreen ? _ctx.$t('common.edit') : '',
              title: _ctx.$t('common.edit'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToStep(0)))
            }, null, 8, ["label", "title"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", {
              textContent: _toDisplayString(_ctx.vm.checkoutForm.companyForm.companyName)
            }, null, 8, _hoisted_4),
            _createElementVNode("p", {
              class: "mb-0",
              textContent: _toDisplayString(_ctx.vm.checkoutForm.companyForm.street)
            }, null, 8, _hoisted_5),
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.vm.checkoutForm.companyForm.postalCode)
              }, null, 8, _hoisted_7),
              _createElementVNode("span", {
                class: "ml-1",
                textContent: _toDisplayString(_ctx.vm.checkoutForm.companyForm.city)
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("p", {
              class: "mb-0",
              textContent: _toDisplayString(_ctx.formatCountry(_ctx.vm.checkoutForm.companyForm.countryCode))
            }, null, 8, _hoisted_9)
          ]),
          _: 1
        })
      ]),
      (_ctx.vm.checkoutForm.useDifferentInvoiceAddress && _ctx.vm.checkoutForm.billingAddressForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_Panel, { toggleable: false }, {
              header: _withCtx(() => [
                _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.$t("checkout.titleBillingAddress")), 1),
                _createVNode(_component_Button, {
                  class: "p-button-raised p-button-text p-button-rounded ml-auto",
                  icon: "pi pi-pencil",
                  label: _ctx.isSmallScreen ? _ctx.$t('common.edit') : '',
                  title: _ctx.$t('common.edit'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToStep(0)))
                }, null, 8, ["label", "title"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "mb-0",
                  textContent: _toDisplayString(_ctx.vm.checkoutForm.billingAddressForm.street)
                }, null, 8, _hoisted_12),
                _createElementVNode("p", _hoisted_13, [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(_ctx.vm.checkoutForm.billingAddressForm.postalCode)
                  }, null, 8, _hoisted_14),
                  _createElementVNode("span", {
                    class: "ml-1",
                    textContent: _toDisplayString(_ctx.vm.checkoutForm.billingAddressForm.city)
                  }, null, 8, _hoisted_15)
                ]),
                _createElementVNode("p", {
                  class: "mb-0",
                  textContent: _toDisplayString(_ctx.formatCountry(_ctx.vm.checkoutForm.billingAddressForm.countryCode))
                }, null, 8, _hoisted_16)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_Panel, { toggleable: false }, {
          header: _withCtx(() => [
            _createElementVNode("h4", _hoisted_18, _toDisplayString(_ctx.$t("checkout.titlePersonalInfo")), 1),
            _createVNode(_component_Button, {
              class: "p-button-raised p-button-text p-button-rounded ml-auto",
              icon: "pi pi-pencil",
              label: _ctx.isSmallScreen ? _ctx.$t('common.edit') : '',
              title: _ctx.$t('common.edit'),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToStep(0)))
            }, null, 8, ["label", "title"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", {
              class: "mb-0",
              textContent: _toDisplayString(_ctx.salutationAndName)
            }, null, 8, _hoisted_19),
            _createElementVNode("p", {
              class: "mb-0",
              textContent: _toDisplayString(_ctx.vm.checkoutForm.personalForm.phone)
            }, null, 8, _hoisted_20)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_Panel, { toggleable: false }, {
          header: _withCtx(() => [
            _createElementVNode("h4", _hoisted_22, _toDisplayString(_ctx.$t("checkout.titlePaymentMethod")), 1),
            _createVNode(_component_Button, {
              class: "p-button-raised p-button-text p-button-rounded ml-auto",
              icon: "pi pi-pencil",
              label: _ctx.isSmallScreen ? _ctx.$t('common.edit') : '',
              title: _ctx.$t('common.edit'),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToStep(1)))
            }, null, 8, ["label", "title"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_23, [
              (_openBlock(), _createElementBlock("svg", _hoisted_24, [
                _createElementVNode("use", { "xlink:href": _ctx.paymentIcon }, null, 8, _hoisted_25)
              ])),
              _createElementVNode("p", {
                textContent: _toDisplayString(_ctx.formattedPaymentMethod)
              }, null, 8, _hoisted_26)
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_component_Panel, { toggleable: false }, {
          header: _withCtx(() => [
            _createElementVNode("h4", {
              class: _normalizeClass(["mb-1", { required: _ctx.orderSummaryForm.isReferenceNumberRequired }])
            }, _toDisplayString(_ctx.$t("checkout.titleReferenceNumber")), 3)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.v$['orderSummaryForm'].reference.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$['orderSummaryForm'].reference.$model) = $event)),
              placeholder: _ctx.$t('checkout.referenceNumber_placeholder')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createVNode(_component_Panel, { toggleable: false }, {
          header: _withCtx(() => [
            _createElementVNode("h4", {
              class: _normalizeClass(["mb-1", { required: _ctx.orderSummaryForm.isInvoiceCopyEmailRequired }])
            }, _toDisplayString(_ctx.$t("checkout.titleCreditorAdministration")), 3)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.v$['orderSummaryForm'].invoiceCopyEmail.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$['orderSummaryForm'].invoiceCopyEmail.$model) = $event)),
              placeholder: _ctx.$t('checkout.invoiceCopyEmail_placeholder')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_30, [
      (_ctx.vm?.cartClient?.itemCount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
            _createVNode(_component_Panel, { toggleable: false }, {
              header: _withCtx(() => [
                _createElementVNode("h4", _hoisted_32, _toDisplayString(_ctx.$t("checkout.titleCart")), 1),
                (!_ctx.canEditCart)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "p-button-text hidden-print p-0",
                      disabled: _ctx.isBusy,
                      icon: "pi pi-pencil",
                      label: _ctx.$t('common.edit'),
                      title: _ctx.$t('common.edit'),
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.canEditCart = !_ctx.canEditCart))
                    }, null, 8, ["disabled", "label", "title"]))
                  : (_ctx.canEditCart)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "p-button-text p-button-success hidden-print p-0",
                        disabled: _ctx.isBusy,
                        icon: "pi pi-check",
                        label: _ctx.$t('checkout.button.doneEditingCart'),
                        title: _ctx.$t('checkout.button.doneEditingCart'),
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.canEditCart = !_ctx.canEditCart))
                      }, null, 8, ["disabled", "label", "title"]))
                    : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_CartOverview, {
                  "cart-client": _ctx.vm?.cartClient,
                  "is-editable": _ctx.canEditCart
                }, null, 8, ["cart-client", "is-editable"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}