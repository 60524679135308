<template>
	<DataTable
		v-if="contracts.length"
		v-model:expanded-rows="expandedRows"
		auto-layout
		data-key="contractNumber"
		responsive-layout="stack"
		:row-class="highlightRow"
		:row-hover="true"
		:value="contracts"
		@row-click.self="onRowClick"
	>
		<Column v-if="!isSmallScreen?.value" expander header-style="width:1.75rem" />
		<Column
			key="contractNumber"
			field="contractNumber"
			:header="$t('myCadac.contractOverview.table.colContractNumber')"
			:sortable="false"
		>
			<template #body="item">
				<p v-if="userProfile?.isCadacSales || userProfile?.isImpersonated" class="mb-0 text-bold">
					<a :href="item.data.crmUrl" target="_blank" :title="$t('common.goToCrm')">
						{{ item.data.contractNumber }}
					</a>
					<small v-if="item.data.crmBusinessUnit" class="d-block text-muted">
						{{
							$t("myCadac.contractOverview.table.lblCrmBusinessUnit", {
								name: item.data.crmBusinessUnit.name,
							})
						}}
					</small>
				</p>
				<p v-else class="mb-0 text-bold">
					{{ item.data.contractNumber }}
				</p>
			</template>
		</Column>
		<Column
			key="products"
			field="products"
			:header="$t('myCadac.contractOverview.table.colProducts')"
			:sortable="false"
		>
			<template #body="item">
				<AvatarGroup>
					<Avatar
						v-for="(asset, index) in getSortedContractAssets(item.data).slice(
							0,
							maxContractAssetsToShow
						)"
						:key="index"
						:alt="asset.productIconAlt"
						class="ml-0 bg-transparent"
						:image="asset.productIconUrl"
						shape="square"
						size="small"
						:title="asset.productName"
					>
					</Avatar>
					<Avatar
						v-if="getContractAssets(item.data).length > maxContractAssetsToShow"
						class="text-bold ml-0"
						:label="`+${getContractAssets(item.data).length - maxContractAssetsToShow}`"
						shape="square"
						size="small"
					>
					</Avatar>
				</AvatarGroup>
			</template>
		</Column>
		<Column
			key="renewalTerm"
			field="renewalTerm"
			:header="$t('myCadac.contractOverview.table.colDuration')"
			:sortable="false"
		/>
		<Column
			key="automaticRenewal"
			field="automaticRenewal"
			:header="$t('myCadac.contractOverview.table.colAutomaticRenewal')"
			:sortable="false"
		>
			<template #body="item">
				<div class="d-flex align-items-center">
					<i
						:class="
							item.data.automaticRenewal
								? 'pi pi-check text-success mr-1'
								: 'pi pi-times text-danger mr-1'
						"
					/>
					<span style="line-height: 1.25rem">{{
						getAutomaticRenewalText(item.data.automaticRenewal)
					}}</span>
				</div>
			</template>
		</Column>
		<Column
			key="endDate"
			field="endDate"
			:header="$t('myCadac.contractOverview.table.colExpiresOn')"
			:sortable="false"
		>
			<template #body="item">
				<div
					v-if="shouldShowContractExpirationWarning(item.data)"
					class="text-danger d-flex flex-nowrap overflow-hidden"
				>
					<span class="text-nowrap mr-1">{{ formatDate(item.data.endDate) }}</span>
					<PopoverIcon class="d-inline-flex" style="line-height: 1.75rem">
						<i class="pi pi-exclamation-circle" />
						<template #content>
							<p>
								{{
									$tc("myCadac.contractOverview.table.contractExpirationWarning", {
										count: item.data.remainingDays,
									})
								}}
							</p>
							<small
								v-if="
									(userProfile?.isCadacSales || userProfile?.isImpersonatedByCadacSales) &&
									item.data.contractFaultRemarks
								"
								class="text-bold"
							>
								{{ item.data.contractFaultRemarks }}
							</small>
						</template>
					</PopoverIcon>
				</div>
				<span v-else>{{ formatDate(item.data.endDate) }}</span>
			</template>
		</Column>
		<Column key="actions" style="width: 25%">
			<template #body="item">
				<div class="d-flex justify-content-end flex-grow-1">
					<Button
						v-if="canExtendOrRenewContract(item.data) && item.data.isPerpetual"
						class="p-button-success p-button-sm mr-auto mr-lg-1 flex-grow-lg-1"
						:label="$t('myCadac.contractOverview.button.switchContractOptions')"
						@click="
							openUrl(`${vm.contractDetailPageUrl}?contractnumber=${item.data.contractNumber}`);
							$event.target.disabled = true;
						"
					/>
					<Button
						v-if="canExtendOrRenewContract(item.data) && !item.data.isPerpetual"
						class="p-button-success p-button-sm mr-auto mr-lg-1 flex-grow-lg-1"
						:label="$t('myCadac.contractOverview.button.renewContract')"
						@click="
							openUrl(`${vm.contractDetailPageUrl}?contractnumber=${item.data.contractNumber}`);
							$event.target.disabled = true;
						"
					/>
					<Button
						class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
						:icon="canEditContract(item.data) ? 'pi pi-pencil' : 'pi pi-eye'"
						:label="
							isSmallScreen?.value
								? canEditContract(item.data)
									? $t('myCadac.contractOverview.button.changeContract')
									: $t('common.view')
								: ''
						"
						:title="
							canEditContract(item.data)
								? $t('myCadac.contractOverview.button.changeContract')
								: $t('common.view')
						"
						@click="
							openUrl(
								`${vm.contractChangePageUrl}?contractnumber=${
									item.data.contractNumber
								}&edit=${canEditContract(item.data)}`
							);
							$event.target.disabled = true;
						"
					/>
					<Button
						v-if="canCancelContract(item.data)"
						class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
						icon="pi pi-times"
						:label="isSmallScreen?.value ? $t('myCadac.contractOverview.button.cancelContract') : ''"
						:title="$t('myCadac.contractOverview.button.cancelContract')"
						@click="cancelContract(item.data)"
					/>
				</div>
			</template>
		</Column>
		<template #expansion="item">
			<div class="row-expansion-content">
				<DataTable
					v-if="getContractAssets(item.data).length"
					auto-layout
					responsive-layout="stack"
					:value="getContractAssets(item.data)"
				>
					<Column
						field="productName"
						:header="$t('myCadac.contractOverview.table.colProductName')"
						:sortable="false"
					>
						<template #body="asset">
							<div class="d-flex align-items-center ml-1 ml-lg-0">
								<Avatar
									:alt="asset.data.productIconAlt"
									class="mr-1 bg-transparent flex-shrink-0"
									:image="asset.data.productIconUrl"
									shape="square"
									size="large"
									:title="asset.data.productName"
								>
								</Avatar>
								<div class="d-flex flex-column">
									<small class="mb-0 text-muted d-block">
										{{ asset.data.productBrand }}
									</small>
									<p
										v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
										class="mb-0 text-bold"
									>
										<a
											:href="asset.data.productCrmUrl"
											target="_blank"
											:title="$t('common.goToCrm')"
										>
											{{
												formatProductName(asset.data.productName, asset.data.productBrand)
											}}
										</a>
									</p>

									<p v-else class="mb-0 text-bold">
										{{ formatProductName(asset.data.productName, asset.data.productBrand) }}
									</p>

									<span
										v-if="asset.data.isMultiSizeProduct"
										class="badge badge-primary multi-user-badge"
									>
										{{ asset.data.multiSizeUnitString }}
									</span>

									<small v-if="isAssetSwitched(asset.data)" class="text-info text-bold">
										{{ $t("myCadac.contractDetail.table.productSwitchedMessage") }}
									</small>
								</div>
							</div>
						</template>
					</Column>
					<Column
						field="serialNumber"
						:header="$t('myCadac.contractOverview.table.colProductSerialNumber')"
						:sortable="false"
						style="width: 20%"
					>
						<template #body="asset">
							<div v-if="isContractExpired(item.data)">{{ $t("common.notApplicable") }}</div>
							<div v-else>
								<p
									v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
									class="mb-0 text-bold"
								>
									<a :href="asset.data.crmUrl" target="_blank" :title="$t('common.goToCrm')">
										{{ asset.data.serialNumber || $t("common.notApplicable") }}
									</a>
								</p>
								<p v-else class="mb-0 text-bold">
									{{ asset.data.serialNumber || $t("common.notApplicable") }}
								</p>
							</div>
						</template>
					</Column>
					<Column
						field="deployment"
						:header="$t('myCadac.contractOverview.table.colProductLicenceType')"
						:hidden="isSupportContractTable"
						:sortable="false"
						style="width: 20%"
					>
						<template #body="asset">
							{{ $t(`enum.deployment.${asset.data.deployment}`) }}
						</template>
					</Column>
					<Column
						field="seats"
						:header="$t('myCadac.contractOverview.table.colProductSeats')"
						:hidden="isSupportContractTable"
						:sortable="false"
						style="width: 20%"
					/>
				</DataTable>
				<Message v-else :closable="false" severity="warn">
					{{ $t("myCadac.contractOverview.message.noContractAssets") }}
				</Message>
			</div>
		</template>
	</DataTable>
	<Card v-if="!contracts.length" class="mx-auto mt-2">
		<template #content>
			<p class="lead">
				{{
					isSupportContractTable || !contracts.length
						? $t("myCadac.contractOverview.card.noSupportContracts")
						: $t("myCadac.contractOverview.card.noContracts")
				}}
			</p>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { Contract } from "@/types/generated/contract";
import { ContractAsset } from "@/types/generated/contractAsset";
import { IContractOverview } from "@/types/viewModels/myCadac/contractOverviewViewModel";
import { PropType } from "vue";
import { SwitchStatus } from "@/types/enum/switchStatus";
import { isAfter } from "date-fns";
import { reduce } from "lodash";
import { ContractType } from "@/types/enum/contractType";

@Component({ emits: ["cancel-contract"] })
export default class ContractOverviewTable extends BaseComponent {
	@Prop({ type: Object as PropType<IContractOverview>, required: true, default: {} })
	vm!: IContractOverview;
	@Prop({ type: Object as PropType<Contract[]>, required: true, default: {} })
	contracts!: Contract[];
	@Prop({ type: Number as PropType<ContractType>, required: true, default: 0 })
	type!: ContractType;

	@Emit() cancelContract(contract: Contract): Contract {
		return contract;
	}

	maxContractAssetsToShow = 3;
	expandedRows: Contract[] = [];

	get isSupportContractTable(): boolean {
		return this.type === ContractType.support;
	}

	onRowClick(event: { data: Contract; index: number }): void {
		const existingRowIndex = this.expandedRows.findIndex(
			(x) => x.contractNumber === event.data.contractNumber
		);

		if (existingRowIndex > -1) {
			this.expandedRows.splice(existingRowIndex, 1);
		} else {
			this.expandedRows.push(event.data);
		}
		this.expandedRows = [...this.expandedRows];
	}

	highlightRow(contract: Contract): string {
		const isRowExpanded =
			this.expandedRows.findIndex((x) => x.contractNumber === contract.contractNumber) > -1;
		return isRowExpanded ? "highlight" : "";
	}

	getAutomaticRenewalText(automaticRenewal: boolean): string {
		return automaticRenewal ? this.$t("common.on") : this.$t("common.off");
	}

	getContractAssets(contract: Contract): ContractAsset[] {
		return contract.isSupportContract ? contract.contractSupportAssets : contract.contractAssets;
	}

	getSortedContractAssets(contract: Contract): ContractAsset[] {
		const otherAssets: ContractAsset[] = [];
		const uniqueIconAssets = reduce(
			this.getContractAssets(contract),
			(result: ContractAsset[], value: ContractAsset) => {
				if (result.findIndex((x) => x.productName === value.productName) === -1) {
					result.push(value);
				} else {
					otherAssets.push(value);
				}
				return result;
			},
			[] as ContractAsset[]
		);
		return [...uniqueIconAssets, ...otherAssets];
	}

	isAssetSwitched(contractAsset: ContractAsset): boolean {
		return contractAsset.switchStatus !== SwitchStatus.notSwitched.valueOf();
	}

	shouldShowContractExpirationWarning(contract: Contract): boolean {
		return (
			(contract.supportsProlongation && contract.remainingDays > 0) ||
			contract.partialContractWarning ||
			contract.prolongationDisabledDueToPendingOrder
		);
	}

	canExtendOrRenewContract(contract: Contract): boolean {
		return (
			contract.supportsProlongation &&
			!contract.isDatechAutoRenewal &&
			!!this.vm.contractDetailPageUrl &&
			(!this.isContractExpired(contract) || !contract.prolongationDisabledDueToPendingOrder)
		);
	}

	canEditContract(contract: Contract): boolean {
		return (
			contract.supportsChanges &&
			!contract.prolongationDisabledDueToPendingOrder &&
			!!this.vm.contractChangePageUrl &&
			!contract.isDatechAutoRenewalCancelled
		);
	}

	canCancelContract(contract: Contract): boolean {
		return contract.isDatechAutoRenewal && contract.isAllowedToCancelDatechAutoRenewal;
	}

	isContractExpired(contract: Contract): boolean {
		return isAfter(new Date(), this.toDate(contract.endDate));
	}
}
</script>

<style lang="scss" scoped>
.product-icon {
	width: 30px;
	height: 30px;
	object-fit: cover;
	object-position: center;
}

::v-deep(.p-datatable-table) {
	.p-button-text {
		background: var(--surface-a);
	}

	.row-expansion-content {
		.p-message {
			margin: 0;
		}
	}
}
</style>
