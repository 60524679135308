import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "col-12 mb-3"
}
const _hoisted_4 = { class: "d-flex flex-column w-100" }
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = { class: "col-12 mb-3" }
const _hoisted_7 = { class: "d-flex justify-content-between w-100" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractOverviewTable = _resolveComponent("ContractOverviewTable")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.pageTitle)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.vm.pageTitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasSupportAssets)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Panel, {
              id: "panel-supportContracts",
              toggleable: false
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("myCadac.contractOverview.titleSupportContracts")), 1)
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ContractOverviewTable, {
                  contracts: _ctx.supportContracts,
                  type: _ctx.contractTypeEnum.support,
                  vm: _ctx.vm,
                  onCancelContract: _ctx.confirmCancelContract
                }, null, 8, ["contracts", "type", "vm", "onCancelContract"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Panel, {
          id: "panel-softwareContracts",
          toggleable: false
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("myCadac.contractOverview.titleContracts")), 1)
              ]),
              (_ctx.vm.licenceManagementOverviewPageUrl)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: _ctx.$t('myCadac.contractOverview.button.manageLicences'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmManageLicences()))
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_ContractOverviewTable, {
              contracts: _ctx.softwareContracts,
              type: _ctx.contractTypeEnum.software,
              vm: _ctx.vm,
              onCancelContract: ($event: any) => ([])
            }, null, 8, ["contracts", "type", "vm"])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}