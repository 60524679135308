import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95034af6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "myCadacMenu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "p-panel-title" }
const _hoisted_4 = { class: "mycadac-menu-items" }
const _hoisted_5 = ["href", "title"]
const _hoisted_6 = {
  key: 0,
  class: "mycadac-menu-items"
}
const _hoisted_7 = ["href", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myCadac.menuTitle")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainCategories, (item, i) => {
      return (_openBlock(), _createBlock(_component_Panel, {
        collapsed: _ctx.panelCollapse[i],
        "onUpdate:collapsed": ($event: any) => ((_ctx.panelCollapse[i]) = $event),
        key: i,
        class: "mb-1"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", {
            class: "d-flex align-items-center",
            onClick: ($event: any) => (_ctx.panelCollapse[i] = !_ctx.panelCollapse[i])
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`enum.myCadacCategory.${item.category}`)), 1),
            _createElementVNode("i", {
              class: _normalizeClass(["p-panel-header-icon", _ctx.panelCollapse[i] ? 'pi pi-chevron-left' : 'pi pi-chevron-down'])
            }, null, 2)
          ], 8, _hoisted_2)
        ]),
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (menuItem) => {
              return (_openBlock(), _createElementBlock("li", {
                key: menuItem,
                class: _normalizeClass(["", { active: menuItem.isActive }])
              }, [
                _createElementVNode("a", {
                  href: menuItem.url,
                  title: menuItem.title
                }, _toDisplayString(menuItem.label), 9, _hoisted_5)
              ], 2))
            }), 128))
          ])
        ]),
        _: 2
      }, 1032, ["collapsed", "onUpdate:collapsed"]))
    }), 128)),
    _createVNode(_component_Divider),
    (_ctx.miscCategory)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.miscCategory.items, (menuItem) => {
            return (_openBlock(), _createElementBlock("li", {
              key: menuItem,
              class: _normalizeClass(["", { active: menuItem.isActive }])
            }, [
              _createElementVNode("a", {
                href: menuItem.url,
                title: menuItem.title
              }, _toDisplayString(menuItem.label), 9, _hoisted_7)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}