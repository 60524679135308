<template>
	<div class="row">
		<div class="col-12 mb-2">
			<h3>{{ $t("checkout.titleSelectPaymentMethod") }}</h3>
			<Message v-if="!hasPaymentOptions" :closable="false" severity="warn">
				{{ $t("checkout.message.noPaymentMethodsAvailable") }}
			</Message>
			<DataTable
				v-if="hasPaymentOptions"
				v-model:selection="selectedPaymentMethod"
				responsive-layout="stack"
				selection-mode="single"
				:value="paymentOptions"
				@row-select="onPaymentMethodSelected($event)"
			>
				<Column
					class="text-lg-center"
					selection-mode="single"
					style="width: 5%; padding: 1rem; overflow: hidden"
				/>
				<Column style="width: 20%; overflow: hidden">
					<template #body="item">
						<svg class="mb-1 mr-1 icon-payment-option">
							<use :xlink:href="item.data.icon" />
						</svg>
					</template>
				</Column>
				<Column style="width: 75%">
					<template #body="item">
						<div class="d-flex flex-column p-2">
							<h4 class="mb-0">
								{{ item.data.title }}
								<small class="font-italic">{{ item.data.cost }}</small>
							</h4>
							<p class="lead text-muted">{{ item.data.subtitle }}</p>
							<Dropdown
								v-if="item.data.options?.length > 0"
								v-model="vm.checkoutForm.selectedPaymentProvider"
								option-label="label"
								option-value="value"
								:options="item.data.options"
								:placeholder="$t('checkout.paymentMethod.selectProvider_placeholder')"
								@change="onPaymentMethodOptionSelected(item)"
							/>
						</div>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";
import { ICheckout } from "@/types/viewModels/commerce/checkoutViewModel";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { PaymentMethod } from "@/types/enum/paymentMethod";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";

export interface IPaymentMethodOption {
	id: string;
	paymentMethod: PaymentMethod;
	icon: string;
	title: string;
	subtitle: string;
	cost?: string;
	options?: IDropdownItem[];
}

@Component
export default class PaymentMethodSelection extends BaseComponent {
	paymentOptions: IPaymentMethodOption[] = [];
	selectedPaymentMethod!: IPaymentMethodOption;
	checkoutStore = useCheckoutStore();

	get vm(): ICheckout {
		return this.checkoutStore.vm as ICheckout;
	}

	get hasPaymentOptions(): boolean {
		return !!this.paymentOptions?.length;
	}

	created(): void {
		this.paymentOptions = this.vm.availablePaymentMethods.map(({ key, value }) => ({
			id: key,
			paymentMethod: value,
			title: this.$t(`enum.paymentMethod.${value}`),
			subtitle: this.getSubtitleForPaymentMethod(value),
			icon: this.getIconForPaymentMethod(value),
			cost: this.getCostForPaymentMethod(value),
			options: this.getOptionsForPaymentMethod(value),
		}));
		this.selectedPaymentMethod = this.paymentOptions.find(
			(x) => x.id === this.vm.checkoutForm.paymentMethod
		) as IPaymentMethodOption;
	}

	onPaymentMethodSelected({ data }: { data: IPaymentMethodOption }): void {
		// Make sure the payment method stays selected (on mobile viewport it will deselect)
		setTimeout(() => (this.selectedPaymentMethod = data), 10);
		this.checkoutStore.setPaymentMethod(data.id);
	}

	onPaymentMethodOptionSelected({ data }: { data: IPaymentMethodOption }): void {
		data.icon = this.getIconForPaymentMethod(data.paymentMethod);
		this.checkoutStore.setPaymentMethod(data.id);
		this.checkoutStore.setSelectedPaymentProvider(this.vm.checkoutForm.selectedPaymentProvider);
	}

	isPaymentOptionValid(): boolean {
		const { selectedPaymentProvider, selectedPaymentMethod } = this.vm.checkoutForm;
		const paymentMethodOption = this.paymentOptions.find((x) => x.paymentMethod === selectedPaymentMethod);
		if (!paymentMethodOption) return false;
		if (paymentMethodOption?.options?.length) {
			return paymentMethodOption.options.findIndex((x) => x.value === selectedPaymentProvider) < 0;
		}
		return true;
	}

	private getIconForPaymentMethod(paymentMethod: PaymentMethod): string {
		switch (paymentMethod) {
			case PaymentMethod.ideal:
				return `#payment-ideal`;
			case PaymentMethod.bancontactMrCash:
				return `#payment-bancontactmrcash`;
			case PaymentMethod.paypal:
				return `#payment-paypal`;
			case PaymentMethod.creditcard:
				return this.vm.creditCardMethods.findIndex(
					(x) => x.key === this.vm.checkoutForm.selectedPaymentProvider
				) > -1
					? `#payment-${this.vm.checkoutForm.selectedPaymentProvider}`
					: `#payment-creditcard`;
			case PaymentMethod.onAccount:
				return `#payment-onaccount`;
			case PaymentMethod.sofort:
				return `#payment-sofort`;
		}
	}

	private getSubtitleForPaymentMethod(paymentMethod: PaymentMethod): string {
		switch (paymentMethod) {
			case PaymentMethod.sofort:
				return this.$t(`checkout.paymentMethod.subtitle_sofort`);
			case PaymentMethod.ideal:
				return this.$t(`checkout.paymentMethod.subtitle_ideal`);
			case PaymentMethod.bancontactMrCash:
				return this.$t(`checkout.paymentMethod.subtitle_mrcash`);
			case PaymentMethod.paypal:
				return this.$t(`checkout.paymentMethod.subtitle_paypal`);
			case PaymentMethod.creditcard:
				return this.$t(`checkout.paymentMethod.subtitle_creditcard`, [
					this.getOptionsForPaymentMethod(paymentMethod)
						.map((x) => x.label)
						.join(", "),
				]);
			case PaymentMethod.onAccount:
				return this.$t(
					`checkout.paymentMethod.paymentCondition.${this.vm.checkoutForm.invoicePaymentConditionCode}`
				);
		}
	}

	private getCostForPaymentMethod(paymentMethod: PaymentMethod): string {
		switch (paymentMethod) {
			case PaymentMethod.sofort:
			case PaymentMethod.ideal:
			case PaymentMethod.bancontactMrCash:
			case PaymentMethod.paypal:
			case PaymentMethod.onAccount:
				return this.$t(`common.free`);
			case PaymentMethod.creditcard:
				return `+${this.$t("common.priceSummary.creditCardSurcharge", [this.vm.creditCardSurtaxPercentage])}`;
		}
	}

	private getOptionsForPaymentMethod(paymentMethod: PaymentMethod): IDropdownItem[] {
		switch (paymentMethod) {
			case PaymentMethod.bancontactMrCash:
			case PaymentMethod.sofort:
			case PaymentMethod.paypal:
			case PaymentMethod.onAccount:
				return [];
			case PaymentMethod.ideal:
				return this.vm.idealIssuers.map((x) => ({ value: x.key, label: x.value }));
			case PaymentMethod.creditcard:
				return this.vm.creditCardMethods.map((x) => ({ value: x.key, label: x.value }));
		}
	}
}
</script>

<style lang="scss" scoped>
.icon-payment-option {
	transform: scale(0.65);
	display: block;
	width: 100%;
}

.text-payment-option {
	flex: 3;
}

::v-deep(.p-datatable) {
	.p-datatable-tbody > tr > td {
		padding: 0 0.25rem;
	}
}
</style>
