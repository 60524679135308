<template>
	<div>
		<slot />
	</div>
	<AdvisorUploadUsageModal
		v-if="advisorStore.uploadUsageModalVisible"
		:api-version="vm.apiVersion"
		:results-page-url="vm.advisorResultsPageUrl"
		:vm="vm.uploadModalViewModel"
		@hideModal="removeAdvisorModalUrlTag"
	/>
</template>

<script lang="ts">
import AdvisorUploadUsageModal from "@/components/advisor/advisorUploadUsageModal.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IAdvisorInfo } from "@/types/viewModels/commerce/advisorInfoViewModel";
import { PropType } from "vue";
import { useAdvisorStore } from "@/store/advisor/advisorStore";

@Component({ components: { AdvisorUploadUsageModal } })
export default class AdvisorInfo extends BaseComponent {
	@Prop({ type: Object as PropType<IAdvisorInfo>, required: true, default: {} })
	vm!: IAdvisorInfo;
	advisorStore = useAdvisorStore();

	mounted(): void {
		if (window.location.hash === "#show-usage-modal") this.checkAdvisorModalUrlTag();
		window.addEventListener("hashchange", this.checkAdvisorModalUrlTag);
	}

	created(): void {
		this.initFreshChatTopic(["product"]);
	}

	beforeUnmount(): void {
		window.removeEventListener("hashchange", this.checkAdvisorModalUrlTag);
	}

	checkAdvisorModalUrlTag(event?: Event): void {
		if (window.location.hash === "#show-usage-modal") {
			this.openAdvisorModal(event);
		}
	}

	openAdvisorModal(event?: Event): void {
		if (event) event.preventDefault();
		this.advisorStore.showModal("UploadUsage");
	}

	removeAdvisorModalUrlTag(): void {
		window.history.replaceState(null, "", window.location.pathname);
	}
}
</script>

<style scoped lang="scss"></style>
