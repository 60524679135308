/* Auto Generated */

export enum WalletTransactionType {
	prepaidCreditSpent,
	prepaidCreditEarned,
	creditSpent,
	creditEarned,
	expiresSoon,
	expired,
	manualEdit,
	cancelledOrder,
	projectOrder,
}
