<template>
	<ProductLayout v-model="showSideBar" :help-block="selectedHelpBlock" :vm="vm">
		<template #tabs>
			<ProductTabs :items="productTabs">
				<template #tabConfiguration>
					<ProductPageContentSection>
						<Message
							v-if="vm.advisorInfoPageUrl && vm.brand?.toLowerCase() === 'autodesk'"
							class="advice-banner"
							:closable="false"
							severity="info"
						>
							<div class="d-flex flex-column mx-1">
								<div class="title">{{ $t("digitalAdvisor.banner.titleDigitalAdvisor") }}</div>
								<div class="content">
									{{ $t("digitalAdvisor.banner.digitalAdvisorSummary") }}
								</div>
							</div>

							<Button
								class="advice-button p-button-success p-button-primary mx-1"
								:label="$t('digitalAdvisor.button.adviceMe')"
								@click="
									openUrl(vm.advisorInfoPageUrl);
									$event.target.disabled = true;
								"
							/>
						</Message>

						<ProductConfiguration v-if="vm.viewModelIsValid">
							<ProductConfigurationStep
								v-if="productStore.availableLicenseModels?.length > 1"
								id="step-type"
							>
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseLicenceModel") }}</span>
									<Button
										v-if="vm.helpBlockLicenseType"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary text-underline"
										:label="vm.helpBlockLicenseType?.linkText"
										@click="showHelpSideBar(vm.helpBlockLicenseType)"
									/>
								</template>
								<template #error>
									<Message
										v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
										:id="`validation-msg-${productValidationEnum.ValidContract}`"
										:closable="false"
										severity="error"
									>
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableLicenseModels"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedLicenseModel"
										:option="option"
										@option-selected="onLicenseModelSelected(option.value)"
									>
									</ProductConfigurationOption>

									<ProductConfigurationFlexLicense
										v-if="isFlexLicense"
										:product-name="vm.name"
										:product-usage-advice="productStore.productUsageAdvice"
										@select-subscription="onLicenseModelSelected(0)"
									/>

									<Message class="advice-banner" :closable="false" severity="info">
										<div class="d-flex flex-column mx-1">
											<div class="title">
												{{ $t("digitalAdvisor.banner.titleDigitalAdvisor") }}
											</div>
											<div class="content">
												{{ $t("digitalAdvisor.banner.digitalAdvisorSummary") }}
											</div>
										</div>

										<Button
											class="advice-button p-button-success p-button-raised p-button-primary mx-1"
											:label="$t('digitalAdvisor.button.adviceMe')"
											@click="
												openUrl(vm.advisorInfoPageUrl);
												$event.target.disabled = true;
											"
										/>
									</Message>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep
								v-if="productStore.availableLicenseClasses?.length > 0 && !isFlexLicense"
								id="step-type"
							>
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseLicenceType") }}</span>
									<Button
										v-if="vm.helpBlockLicenseType"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary text-underline"
										:label="vm.helpBlockLicenseType?.linkText"
										@click="showHelpSideBar(vm.helpBlockLicenseType)"
									/>
								</template>
								<template #error>
									<Message
										v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
										:id="`validation-msg-${productValidationEnum.ValidContract}`"
										:closable="false"
										severity="error"
									>
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableLicenseClasses"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedLicenseClass"
										:option="option"
										@option-selected="onLicenseClassSelected(option.value)"
									>
									</ProductConfigurationOption>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep
								v-if="productStore.availableLicenseSizes?.length > 0 && !isFlexLicense"
								id="step-size"
							>
								<template #header>
									<span class="mr-1">
										{{ vm.unitSelectionTitle || $t("product.lblChooseLicenceSize") }}
									</span>
									<Button
										v-if="vm.helpBlockProductSize"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockProductSize?.linkText"
										@click="showHelpSideBar(vm.helpBlockProductSize)"
									/>
								</template>
								<template #error>
									<Message
										v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
										:id="`validation-msg-${productValidationEnum.ValidContract}`"
										:closable="false"
										severity="error"
									>
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableLicenseSizes"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedLicenseSize"
										:option="option"
										@option-selected="onLicenseSizeSelected(option.value)"
									>
									</ProductConfigurationOption>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep
								v-if="
									productStore.canAddToContract &&
									productStore.isAddingToContract &&
									!isFlexLicense
								"
								id="step-contract"
							>
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseContract") }}</span>
									<Button
										v-if="vm.helpBlockContract"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockContract?.linkText"
										@click="showHelpSideBar(vm.helpBlockContract)"
									/>
								</template>
								<ProgressSpinner
									v-if="isSpinnerVisible('contractTable')"
									animation-duration=".75s"
									class="d-block mr-auto ml-0"
									stroke-width="3"
								/>
								<DataTable
									v-else
									v-model:selection="productStore.selectedContract"
									auto-layout
									data-key="contractNumber"
									selection-mode="single"
									:value="productStore.contractsForProduct"
									@row-select="onContractSelected"
								>
									<Column
										class="text-lg-center"
										selection-mode="single"
										style="width: 5%; padding: 1rem; overflow: hidden"
									/>
									<Column
										key="contractNumber"
										field="contractNumber"
										:header="$t('contract.contractNumber')"
									>
										<template #body="item">
											<p
												v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
												class="mb-0 text-bold"
											>
												<a
													href="javascript:void(0)"
													:title="$t('common.goToCrm')"
													@click="openUrl(item.data.crmUrl, '_blank')"
												>
													{{ item.data.contractNumber }}
												</a>
											</p>
											<p v-else class="mb-0 text-bold">
												{{ item.data.contractNumber }}
											</p>
										</template>
									</Column>
									<Column key="endDate" field="endDate" :header="$t('contract.endDate')">
										<template #body="item">
											<span>
												{{ formatDate(item.data.endDate) }}
											</span>
										</template>
									</Column>
									<Column key="remainingTerm" :header="$t('contract.remainingDuration')">
										<template #body="item">
											<span>
												{{ $tc("common.months", item.data.duration) }}
											</span>
										</template>
									</Column>
									<Column :header="`${$t('common.costs')} ${$t('common.pricePerMonth')}`">
										<template #body="item">
											<span class="text-bold">
												{{ formatCurrency(item.data.customerPrice) }}
											</span>
										</template>
									</Column>
								</DataTable>
								<Button
									v-if="productStore.canAddToContract"
									class="py-0 mt-2 p-button-text p-button-link"
									:label="$t('product.button.dontAddToExistingContract')"
									@click="toggleAddToContract"
								/>
							</ProductConfigurationStep>

							<ProductConfigurationStep v-else-if="!isFlexLicense" id="step-duration">
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseDuration") }}</span>
									<Button
										v-if="vm.helpBlockDuration"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockDuration?.linkText"
										@click="showHelpSideBar(vm.helpBlockDuration)"
									/>
								</template>
								<template #error>
									<Message
										v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
										:id="`validation-msg-${productValidationEnum.ValidContract}`"
										:closable="false"
										severity="error"
									>
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableDurations"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedDuration"
										:option="option"
										@option-selected="onDurationSelected(option.value)"
									>
										<template #usp-pricePerMonth>
											<span :class="{ 'text-muted': !isCheapestDuration(option.value) }">
												{{
													$t("common.pricePerMonth", [
														formatCurrency(getPricePerMonthForDuration(option.value)),
													])
												}}
											</span>
										</template>
										<template #usp-cheapest>
											<span v-if="isCheapestDuration(option.value)" class="text-success">
												<i class="pi pi-check-circle mr-1" />
												{{ $t("product.bestPrice") }}
											</span>
										</template>
									</ProductConfigurationOption>
								</template>

								<Message
									v-if="!layoutStore.isAuthenticated && !productStore.canAddToContract"
									:closable="false"
									severity="custom"
								>
									<i18n-t keypath="product.message.loginToAddToContract">
										<template #loginLink>
											<a :href="loginPageUrl">{{ $t("common.login") }}</a>
										</template>
									</i18n-t>
								</Message>
								<Button
									v-if="productStore.canAddToContract"
									class="py-0 mt-2 p-button-text p-button-link"
									:label="$t('product.button.addToExistingContract')"
									@click="toggleAddToContract"
								/>
							</ProductConfigurationStep>
						</ProductConfiguration>
						<Message v-else :closable="false" severity="error">
							{{ $t("product.message.currentlyUnavailable") }}
						</Message>
					</ProductPageContentSection>
					<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
						<h3 class="">{{ $t("product.titleUpSell") }}</h3>
						<div class="row">
							<div v-for="product in vm.relatedUpSellProducts" :key="product" class="col-md-6 mb-2">
								<BaseProductOverviewItemCard is-cross-sell :vm="product" />
							</div>
						</div>
					</ProductPageContentSection>
				</template>
				<template #tabInfo>
					<ProductPageContentSection v-if="hasTab('tabInfo')">
						<h3>{{ vm.productInfoTabTitle || $t("product.tabProductInfoTitle") }} {{ vm.name }}</h3>
						<div class="rte" v-html="vm.productInfoTab" />
					</ProductPageContentSection>
					<ProductPageContentSection v-if="$slots.blocks" type="content">
						<slot name="blocks" />
					</ProductPageContentSection>
				</template>
				<template #tabSpecifications>
					<ProductPageContentSection v-if="hasTab('tabSpecifications')" type="content">
						<h3>
							{{ vm.specificationsTabTitle || $t("product.tabSpecificationsTitle") }} {{ vm.name }}
						</h3>
						<div class="product-specification" v-html="vm.specificationsTab" />
					</ProductPageContentSection>
				</template>
				<template #tabContent>
					<ProductPageContentSection v-if="hasTab('tabContent')" type="content">
						<h3>{{ $t("product.tabContentTitle") }} {{ vm.name }}</h3>
						<div class="" v-html="contentTabVm" />
					</ProductPageContentSection>
				</template>
				<template #tabMedia>
					<ProductPageContentSection v-if="hasTab('tabMedia')" type="media">
						<h3>{{ mediaEmbedCaption }}</h3>
						<embed
							height="100%"
							:src="mediaEmbedSrc"
							style="min-height: 400px"
							:title="mediaEmbedCaption"
							type="video/mp4"
							width="100%"
							wmode="transparent"
						/>
					</ProductPageContentSection>
				</template>
			</ProductTabs>
		</template>
		<template #summary>
			<ProductPageSummary
				:can-submit="!submitted || validateProductConfiguration()"
				:is-in-edit-mode="isInEditMode"
				:is-product-available="vm.viewModelIsValid && !isFlexLicense"
				:max-amount="productStore.selectedVariation?.maxQuantity"
				:min-amount="productStore.selectedVariation?.minQuantity"
				:offer-request-form-url="vm.productGuid"
				show-amount-input
				:total-price="priceSummary?.totalPrice"
				:value="productStore?.selectedAmount"
				:variation-guid="productStore.selectedVariation?.guid"
				:variation-id="productStore.selectedVariation?.id"
				@addToCart="addProductToCart"
				@amountChanged="onSelectedAmountChanged"
			>
				<template #priceSummary>
					<PriceSummary :vm="priceSummary" />
					<p class="text-muted lead text-right">{{ $t("common.productSummary.allPricesExclVat") }}</p>
				</template>
				<template #configurationSummary>
					<ProductConfigurationSummary
						:header="$t('product.titleSummaryProductSubscription', [vm.name])"
					>
						<ProductConfigurationSummaryItem
							:content="$t(`enum.licenseClass.${productStore.selectedLicenseClass || 0}`)"
							:label="$t('product.lblLicenceType')"
						/>
						<ProductConfigurationSummaryItem
							v-if="vm.unitSelectionTitle && productStore.selectedLicenseSize"
							:content="productStore.selectedLicenseSizeLabel"
							:label="vm.unitSelectionTitle"
						/>
						<ProductConfigurationSummaryItem
							:content="$tc(`common.months`, productStore.selectedDuration)"
							:label="$t('product.lblDuration')"
						/>
						<ProductConfigurationSummaryItem
							v-if="layoutStore.canImpersonate"
							:content="productStore.selectedVariation?.sku"
							label="SKU"
						/>
					</ProductConfigurationSummary>
					<div v-if="vm.currentPartner">
						<p class="lead mt-2">{{ $t("product.lblYourPartner") }}</p>
						<Button
							class="p-button-text p-button-arrow btn-partner-info"
							@click="showPartnerInfo = true"
						>
							<div class="align-self-center mr-1">
								<Avatar shape="circle" size="large">
									<img
										v-if="vm.currentPartner?.iconUrl"
										v-lazy="vm.currentPartner?.iconUrl"
										:alt="vm.currentPartner?.iconAlt"
										:title="vm.currentPartner?.name"
									/>
								</Avatar>
							</div>
							<div class="d-flex flex-column align-items-start flex-1 text-left">
								<p class="mb-0 font-weight-bold">
									{{ vm.currentPartner?.name }}
								</p>
								<p class="mb-0 text-muted font-weight-normal">
									{{ vm.currentPartner?.addressString }}
								</p>
							</div>
						</Button>
					</div>
				</template>
				<template #validationMessage>
					<Message
						v-if="validationErrors.length && submitted"
						:closable="false"
						severity="warn"
						style="cursor: pointer"
						@click="scrollToFirstValidationError()"
					>
						{{ $tc("common.productSummary.msgValidationErrors", validationErrors.length) }}
					</Message>
				</template>
				<template #walletMessage>
					<WalletMessage
						v-show="!isSmallScreen && vm?.canUseWallet && parseInt(productStore.walletAmount) !== 0"
						:wallet-amount="productStore.walletAmount"
					/>
				</template>
			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
	<PartnerInfoModal v-if="showPartnerInfo" :vm="vm.currentPartner" @cancel="showPartnerInfo = false" />
</template>

<script lang="ts">
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import PartnerInfoModal from "@/components/partners/partnerInfoModal.vue";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationOption from "@/components/product/productConfigurationOption.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import ProductLayout from "@/views/layout/productLayout.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
import ProductPageSummary from "@/components/product/productPageSummary.vue";
import ProductTabs, { ProductTab } from "@/components/product/productTabs.vue";
import ProgressSpinner from "primevue/progressspinner";
import WalletMessage from "@/components/common/WalletMessage.vue";
import toFixed from "accounting-js/lib/toFixed.js";
import { Component, Prop } from "vue-facing-decorator";
import { ContractsForProduct } from "@/types/generated/Api/contractsForProduct";
import { HelpBlockViewModel } from "@/types/generated/Common/helpBlockViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ISoftwareProduct } from "@/types/viewModels/commerce/softwareProductViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { LicenseClass } from "@/types/enum/licenseClass";
import { Log } from "@/types/helpers/logHelper";
import { ProductVariation } from "@/types/models/software/productVariation";
import { PropType } from "vue";
import { SoftwareItemViewModel } from "@/types/models/software/softwareItemViewModel";
import { SoftwareProductValidation } from "@/types/enum/softwareProductValidation";
import { min } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";
import { useSoftwareProductStore } from "@/store/software/softwareProductStore";
import ProductConfigurationFlexLicense from "@/components/product/productConfigurationFlexLicense.vue";
import { LicenseModel } from "@/types/enum/licenseModel";
import { ProductCategoryType } from "@/types/enum/productCategoryType";

// PRICE EXPLANATIONS
// basePrice = Advies prijs
// basePriceMonthly = Advies prijs gedeeld door aantal maanden van duration
// ecommerceBasePrice = Rekenprijs e - commerce.Is het minimale dat Cadac moet verdienen om quitte te spelen op dit product. (inkoop prijs - kickback fee schatting Autodesk)
// ecommercePrice = Goedkoopste prijs op de markt -> is wat je als niet ingelogde gebruiker zou moeten zien.
// ecommerceBasePriceMonthly = commerceBasePrice gedeeld door aantal maanden van duration
// ecommercePriceMonthly = ecommercePriceMonthly gedeeld door aantal maanden van duration
// personalPrice = te betalen prijs(als je bent ingelogd)
// personalPriceMonthly te betalen prijs(als je bent ingelogd) gedeeld door aantal maanden van duration

@Component({
	components: {
		PartnerInfoModal,
		ProductLayout,
		ProductPageSummary,
		PopoverIcon,
		PriceSummary,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationOption,
		ProductConfigurationStep,
		ProductConfigurationSummary,
		ProductConfigurationSummaryItem,
		ProductOverviewItemCard,
		ProductPageContentSection,
		ProductTabs,
		ProgressSpinner,
		WalletMessage,
		ProductConfigurationFlexLicense,
		BaseProductOverviewItemCard,
	},
})
export default class SoftwareProduct extends BaseComponent {
	@Prop({ type: Object as PropType<ISoftwareProduct>, required: true, default: {} }) vm!: ISoftwareProduct;
	showSideBar = false;
	showPartnerInfo = false;
	submitted = false;
	selectedHelpBlock!: HelpBlockViewModel | null;

	productValidation: Map<SoftwareProductValidation, boolean> = new Map<SoftwareProductValidation, boolean>();

	cartStore = useCartStore();
	productStore = useSoftwareProductStore();

	created(): void {
		this.productStore.vm = this.vm;
		this.initFreshChatTopic(["product"]);

		Log.info("SoftwareProduct", this.vm);

		if (this.vm.viewModelIsValid) {
			this.productStore.init();
		} else {
			Log.error(new Error(this.vm.viewModelErrors?.join(", ")));
		}

		this.setDataLayer.viewItem(
			toFixed(
				this.layoutStore.isAuthenticated
					? this.priceSummary.totalPersonalPrice
					: this.productStore.selectedVariation?.basePrice,
				2
			),
			this.productStore.selectedVariation ? [this.productStore.selectedVariation] : [],
			this.productStore?.selectedAmount.toString(),
			this.vm.brand,
			this.vm.name,
			ProductCategoryType[this.vm.productCategory]
		);
	}

	get isFlexLicense(): boolean {
		return this.productStore.selectedLicenseModel === LicenseModel.flex;
	}

	get productValidationEnum(): typeof SoftwareProductValidation {
		return SoftwareProductValidation;
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Software,
			} as unknown as SoftwareItemViewModel,
			crossSellProducts: this.vm.relatedCrossSellProducts,
		};
	}

	get isInEditMode(): boolean {
		return this.vm.oldLineItemId != null && this.vm.oldLineItemId > 0;
	}

	get contentTabVm(): string {
		if (!this.vm.relatedProducts || !this.vm.relatedNotForSaleProducts?.length) return "";
		const allProductNames = [
			...this.vm.relatedProducts.map((x) => x.name),
			...this.vm.relatedNotForSaleProducts,
		];
		return allProductNames?.map((x) => `<li>${x}</li>`).join("");
	}

	get hasTab(): (id: string) => boolean {
		return (id: string) => this.productTabs.findIndex((x) => x.id === id) > -1;
	}

	get productTabs(): ProductTab[] {
		const productTabs: ProductTab[] = [];
		if (!this.vm.latestVersionOfProduct) {
			productTabs.push({
				label: this.$t("product.configureSubscription"),
				id: "tabConfiguration",
			});
		}
		if (this.vm.productInfoTab) {
			productTabs.push({
				label: this.vm.productInfoTabTitle || this.$t("product.tabProductInfoTitle"),
				id: "tabInfo",
			});
		}
		if (this.vm.specificationsTab) {
			productTabs.push({
				label: this.vm.specificationsTabTitle || this.$t("product.tabSpecificationsTitle"),
				id: "tabSpecifications",
			});
		}
		if (this.vm.mediaTab) {
			productTabs.push({
				label: this.vm.mediaTabTitle || this.$t("product.tabMediaTitle"),
				id: "tabMedia",
			});
		}
		if (this.contentTabVm) {
			productTabs.push({
				label: this.$t("product.tabContentTitle"),
				id: "tabContent",
			});
		}
		return productTabs;
	}

	get validationErrors(): boolean[] {
		return [...this.productValidation.values()].filter((x) => !x);
	}

		get priceSummary(): IPriceSummary {
			if (!this.productStore.selectedVariation) return {} as IPriceSummary;
            const { selectedAmount, selectedVariation, selectedDuration } = this.productStore;
			let { basePriceMonthly, ecommercePriceMonthly, personalPriceMonthly, duration } = selectedVariation;

			if (this.productStore.isAddingToContract) {
				duration = selectedDuration;
			}

		const totalBasePrice = (basePriceMonthly || 0) * (selectedAmount || 1) * (duration || 1);
		const totalEcommercePrice = (ecommercePriceMonthly || 0) * (selectedAmount || 1) * (duration || 1);

		const totalPersonalPrice = (personalPriceMonthly || 0) * (selectedAmount || 1) * (duration || 1);

		let totalDiscount = 0;
		let totalPrice = 0;

		if (this.layoutStore.isAuthenticated) {
			totalDiscount = totalBasePrice - totalPersonalPrice;
			totalPrice = totalPersonalPrice;
		} else if (this.vm.hasDifferentEcommercePrice) {
			totalDiscount = totalBasePrice - totalEcommercePrice;
			totalPrice = totalEcommercePrice;
		} else {
			totalPrice = totalBasePrice;
		}

		return {
			basePrice: totalBasePrice,
			totalDiscount,
			totalPersonalPrice: totalDiscount ? totalPersonalPrice : 0,
			totalPrice,
		} as IPriceSummary;
	}

	get mediaEmbedSrc(): string {
		const mediaTabContent = this.vm.mediaTab?.replace("<p>", "").replace("</p>", "");
		let videoUrl = mediaTabContent;
		if (mediaTabContent.includes("|")) {
			const parts = mediaTabContent.split("|");
			videoUrl = parts.length > 1 ? parts[1] : parts[0];
		}
		return videoUrl;
	}

	get mediaEmbedCaption(): string {
		const mediaTabContent = this.vm.mediaTab?.replace("<p>", "").replace("</p>", "");
		let caption = `${this.$t("product.tabMediaTitle")} ${this.vm.name}`;
		if (mediaTabContent.includes("|")) {
			const parts = mediaTabContent.split("|");
			caption = parts[0];
		}
		return caption;
	}

	toggleAddToContract(): void {
		this.productStore.isAddingToContract = !this.productStore.isAddingToContract;
		this.productStore.updateConfiguration();
	}

	addProductToCart(): void {
		this.submitted = true;
		if (!this.validateProductConfiguration()) {
			setTimeout(() => this.scrollToFirstValidationError(), 50);
			return;
		}
		this.loadingStore.increaseLoadingCount();
		this.productStore
			.addToCart()
			.then(() => {
				this.cartStore.showModal("ProductAdded");

				const priceNotLoggedIn =
					(this.productStore.selectedVariation?.basePrice || 0) * this.productStore?.selectedAmount;

				this.setDataLayer.addToCart(
					this.layoutStore.isAuthenticated
						? toFixed(this.priceSummary.totalPersonalPrice, 2)
						: toFixed(priceNotLoggedIn, 2),
					this.productStore.selectedVariation ? [this.productStore.selectedVariation] : [],
					this.productStore?.selectedAmount.toString(),
					this.vm.brand,
					this.vm.name,
					ProductCategoryType[this.vm.productCategory]
				);
			})
			.catch(() =>
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("common.messages.addToCartError"),
					life: 3000,
				})
			)
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

		getPricePerMonthForDuration(duration: number): number {
			const productVariation = this.vm.productVariations?.find(
				(x) =>
					x.duration === duration &&
					x.licenseClass === this.productStore.selectedLicenseClass &&
					x.licenseSize === this.productStore.selectedLicenseSize
			);

			const price = this.layoutStore.isAuthenticated ? productVariation?.personalPriceMonthly : productVariation?.ecommercePriceMonthly;
			return price || 0;
		}

	getPricesPerMonthForAvailableDurations(): number[] {
		return this.productStore.availableDurations.map((x) =>
			this.getPricePerMonthForDuration(x.value as number)
		);
	}

	isCheapestDuration(duration: number): boolean {
		const pricesPerMonth = this.getPricesPerMonthForAvailableDurations();
		//Check if prices are the same, return false if so.
		if (new Set(pricesPerMonth)?.size === 1) return false;
		return this.getPricePerMonthForDuration(duration) === min(pricesPerMonth);
	}

	showHelpSideBar(helpBlock: HelpBlockViewModel) {
		this.selectedHelpBlock = helpBlock;
		this.showSideBar = true;
	}

	onContractSelected({ data }: { data: ContractsForProduct }) {
		this.productStore.updateConfiguration(data);
	}

	onSelectedAmountChanged(value: number): void {
		this.productStore.selectedAmount = value;
		this.productStore.updateConfiguration();
	}

	onLicenseModelSelected(value: LicenseModel) {
		this.productStore.selectedLicenseModel = value;
	}

	onLicenseClassSelected(value: LicenseClass) {
		if (value === this.productStore.selectedLicenseClass) return;
		this.productStore.selectedLicenseClass = value;
		const filterOptions = this.productStore.isAddingToContract
			? {
					licenseClass: value,
				}
			: {
					duration: this.productStore.selectedDuration,
					licenseClass: this.productStore.selectedLicenseClass,
				};
		this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
		this.productStore.updateConfiguration();
	}

	onLicenseSizeSelected(value: number) {
		if (value === this.productStore.selectedLicenseSize) return;
		this.productStore.selectedLicenseSize = value;
		const filterOptions = {
			licenseSize: value,
			licenseClass: this.productStore.selectedLicenseClass,
		};
		this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
		this.productStore.updateConfiguration();
	}

	onDurationSelected(value: number) {
		if (value === this.productStore.selectedDuration) return;
		this.productStore.selectedDuration = value;
		const filterOptions: Partial<ProductVariation> = this.productStore.isAddingToContract
			? {
					licenseClass: this.productStore.selectedLicenseClass,
					licenseSize: this.productStore.selectedLicenseSize,
				}
			: {
					duration: value,
					licenseClass: this.productStore.selectedLicenseClass,
					licenseSize: this.productStore.selectedLicenseSize,
				};
		this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
		this.productStore.updateConfiguration();
		this.setDataLayer.customizeProduct(this.vm.name, this.productStore.selectedVariation);
	}

	private validateProductConfiguration(): boolean {
		this.productValidation.set(SoftwareProductValidation.ValidContract, true);
		return this.validationErrors.length === 0;
	}
}
</script>

<style scoped lang="scss">
.product-config-step-subtitle {
	font-size: 1rem;
	font-weight: 400;
	color: var(--text-color-secondary);
}

.header-explanation-text {
	font-size: 0.9rem !important;
}

.btn-partner-info {
	width: 100%;

	&:hover {
		background-color: inherit !important;
		box-shadow:
			0px 3px 1px -2px rgb(0 0 0 / 20%),
			0px 2px 2px 0px rgb(0 0 0 / 14%),
			0px 1px 5px 0px rgb(0 0 0 / 12%);
	}
}

.advice-banner {
	background: #dcf6e5;
	border: none;
	border-radius: var(--border-radius);
	padding: 0.25rem 0.75rem;
	color: var(--success);

	::v-deep(.p-message-text) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@media (max-width: 960px) {
			flex-direction: column;
			padding-right: 1rem;
			padding-left: 1rem;
			gap: 1rem;
			align-items: start;
		}
	}

	::v-deep(.p-message-icon) {
		color: var(--success);
		padding-right: 0.75rem;
	}

	::v-deep(.p-button) {
		width: 16rem;

		@media (max-width: 576px) {
			width: 100%;
		}
	}

	.title {
		font-weight: 500;
	}

	.content {
		font-weight: 400;
	}
}
</style>
