<template>
	<p v-if="vm.basePrice" class="">
		<span class="text-bold price-label">
			{{ $t("common.priceSummary.basePrice") }}
		</span>
		<span class="text-bold">
			{{ formatCurrency(vm.basePrice) }}
		</span>
	</p>

	<p v-for="([key, value], index) in vm.standardLines?.entries()" :key="index" class="">
		<span class="text-bold price-label">
			{{ key }}
		</span>
		<span class="text-bold flex-shrink-0">
			{{ formatCurrency(value) }}
		</span>
	</p>
	
	<p v-for="([key, value], index) in vm.additionalLines?.entries()" :key="index" class="text-muted">
		<span class="price-label">
			{{ key }}
		</span>
		<span class="prefix-plus flex-shrink-0">
			{{ formatCurrency(value) }}
		</span>
	</p>

	<p v-if="vm.discount" class="text-success">
		<span v-if="layoutStore.isAuthenticated" class="price-label">
			{{ $t("common.priceSummary.personalDiscount") }}
		</span>
		<span v-else class="price-label">
			{{ $t("common.priceSummary.storeDiscount") }}
		</span>
		<span class="prefix-min flex-shrink-0">
			{{ formatCurrency(vm.discount) }}
		</span>
	</p>

	<div v-if="!vm.totalDiscount">
		<p v-for="([key, value], index) in vm.discountLines?.entries()" :key="index" class="text-success">
			<span class="price-label">
				{{ key }}
			</span>
			<span class="prefix-min flex-shrink-0">
				{{ formatCurrency(value) }}
			</span>
		</p>
	</div>

	<p v-if="
	   vm.subtotal &&
	   ((vm.basePrice>
		0 && vm.basePrice !== vm.subtotal) ||
		vm.additionalLines?.size ||
		vm.discountLines?.size)
		"
		class="text-bold"
		:class="{ 'line-discount': vm.discount || vm.additionalLines?.size }"
		>
		<b v-if="layoutStore.isAuthenticated && vm.discount" class="price-label">
			{{ $t("common.priceSummary.yourPrice") }}
		</b>
		<b v-else>{{ $t("common.priceSummary.subtotal") }}</b>
		<b>{{ formatCurrency(vm.subtotal) }}</b>
	</p>

	<div v-if="vm.totalDiscount || vm.totalInstallPrice">
		<p v-if="vm.totalBasePrice" class="text-bold">
			<span class="price-label">
				{{ $t("common.priceSummary.totalBasePrice") }}
			</span>
			<span>
				<b>{{ formatCurrency(vm.totalBasePrice) }}</b>
			</span>
		</p>

		<p v-if="vm.totalInstallPrice">
			<span class="price-label">
				{{ $t("common.priceSummary.installHelp") }}
			</span>
			<span class="prefix-plus flex-shrink-0">
				{{ formatCurrency(vm.totalInstallPrice) }}
			</span>
		</p>

		<div v-if="vm.totalCouponDiscount && vm.totalDiscount - vm.totalCouponDiscount">
			<p class="text-success">
				<span v-if="layoutStore.isAuthenticated" class="price-label">
					{{ $t("common.priceSummary.personalDiscount") }}
				</span>
				<span v-else class="price-label">
					{{ $t("common.priceSummary.storeDiscount") }}
				</span>
				<span class="prefix-min flex-shrink-0">
					{{ formatCurrency(vm.totalDiscount - vm.totalCouponDiscount) }}
				</span>
			</p>
		</div>

		<p v-for="([key, value], index) in vm.discountLines?.entries()" :key="index" class="text-success">
			<span class="price-label">
				{{ key }}
			</span>
			<span class="prefix-min flex-shrink-0">
				{{ formatCurrency(value) }}
			</span>
		</p>

		<p v-if="vm.totalCouponDiscount" class="text-success text-bold">
			<span v-if="layoutStore.isAuthenticated" class="price-label">
				{{ $t("common.priceSummary.totalPersonalDiscount") }}
			</span>
			<span v-else class="price-label">
				{{ $t("common.priceSummary.totalStandardDiscount") }}
			</span>
			<span class="prefix-min flex-shrink-0">
				<b>{{ formatCurrency(vm.totalDiscount) }}</b>
			</span>
		</p>

		<p v-else-if="vm.totalDiscount" class="text-success text-bold">
			<span v-if="layoutStore.isAuthenticated" class="price-label">
				{{ $t("common.priceSummary.personalDiscount") }}
			</span>
			<span v-else class="price-label">
				{{ $t("common.priceSummary.storeDiscount") }}
			</span>
			<span class="prefix-min flex-shrink-0">
				{{ formatCurrency(vm.totalDiscount) }}
			</span>
		</p>
	</div>

	<p v-if="vm.totalPersonalPrice"
	   class="text-bold"
	   :class="{ 'line-discount': vm.totalDiscount, 'mt-2': !vm.totalDiscount }">
		<b>
			<span v-if="layoutStore.isAuthenticated && vm.totalDiscount" class="price-label">
				{{ $t("common.priceSummary.yourPrice") }}
			</span>
			<span v-else class="price-label">{{ $t("common.priceSummary.subtotal") }}</span>
		</b>
		<b>{{ formatCurrency(vm.totalPersonalPrice) }}</b>
	</p>
	<div v-if="vm.vatPercentage && vm.vatAmount">
		<p class="text-muted">
			<span class="price-label">
				{{ $t("common.priceSummary.vatPercentage", [vm.vatPercentage]) }}
				{{ $t("common.vat") }}
			</span>
			<span class="prefix-plus flex-shrink-0">
				{{ formatCurrency(vm.vatAmount) }}
			</span>
		</p>
	</div>

	<div v-if="vm.creditCardSurchargePercentage && vm.creditCardSurchargeAmount">
		<p class="text-muted">
			<span class="price-label">
				{{ $t("common.priceSummary.creditCardSurcharge", [vm.creditCardSurchargePercentage]) }}
			</span>
			<span class="prefix-plus flex-shrink-0">
				{{ formatCurrency(vm.creditCardSurchargeAmount) }}
			</span>
		</p>
	</div>

	<h4 v-if="vm.totalPrice !== undefined" class="text-primary">
		<span class="price-label">
			{{ $t("common.priceSummary.totalPrice") }}
			{{ vm.vatPercentage > 0 ? $t("common.inclVat") : "" }}
		</span>
		<span>
			<b>{{ formatCurrency(vm.totalPrice) }}</b>
		</span>
	</h4>

	<p v-if="vm.totalPrice > 0" class="text-muted">
		<span v-if="vm.vatSituation === vatSituation.VatZeroPercent">
			{{ $t("common.priceSummary.vatPercentageZero") }}
		</span>
		<span v-else-if="vm.vatSituation === vatSituation.ReverseVat">
			{{ $t("common.priceSummary.vatReverseCharged") }}
		</span>
		<span v-else-if="vm.vatSituation === vatSituation.NoVat">
			{{ $t("common.priceSummary.noVat") }}
		</span>
	</p>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Prop } from "vue-facing-decorator";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import BaseComponent from "@/components/base/baseComponent.vue";
import { VatSituation } from "@/types/enum/vatSituation";

@Component
export default class PriceSummary extends BaseComponent {
	@Prop({ type: Object as PropType<IPriceSummary>, required: true, default: {} })
	vm!: IPriceSummary;

	get vatSituation(): typeof VatSituation {
		return VatSituation;
	}
}
</script>

<style lang="scss" scoped>
.price-label {
	text-align: left;
	margin-right: 1rem;
}

div {
	text-align: right;

	p {
		font-size: 1.05rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.25rem;
	}

	h4 {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		margin-top: 0.75rem;
	}
}

.line-discount {
	border-top: 1px solid;
}
</style>
