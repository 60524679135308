<template>
	<ProductLayout :vm="vm">
		<template #configuration>
			<ProductConfiguration class="mb-3">
				<ProductConfigurationStep id="step-software">
					<template #header>
						<span class="mr-1"> {{ $t("product.lblChooseSoftware") }}</span>
						<PopoverIcon
							v-if="vm.supportContract?.endDate"
							class="d-inline-flex text-info"
							placement="right"
						>
							<i class="pi pi-exclamation-circle" />
							<template #content>
								<p>
									<i18n-t keypath="product.message.contractRenewalDate">
										<template #date>
											<b>{{ formatDate(vm.supportContract.endDate) }}</b>
										</template>
									</i18n-t>
								</p>
							</template>
						</PopoverIcon>
					</template>
					<Message v-if="!layoutStore.isAuthenticated" :closable="false" severity="custom">
						<i18n-t keypath="product.message.alreadyHaveSupportLogin">
							<template #loginLink>
								<a :href="loginPageUrl">{{ $t("common.login") }}</a>
							</template>
						</i18n-t>
					</Message>
					<div class="p-inputgroup">
						<AutoComplete
							v-model="selectedProducts"
							force-selection
							:min-length="2"
							:multiple="true"
							option-label="title"
							:placeholder="$t('myCadac.supportOverview.autocomplete.placeholder')"
							:suggestions="searchProductResults"
							@complete="onSearchTrigger($event)"
						>
							<template #option="{ option }">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-items-center">
										<Avatar
											v-if="option.icon"
											alt="product-icon"
											class="mr-1 bg-transparent"
											:image="option.icon"
											shape="square"
										/>
										<Avatar
											v-else
											alt="product-icon"
											class="mr-1 bg-transparent"
											shape="square"
										>
											<svg height="30" width="30">
												<use xlink:href="#icon-cadac"></use>
											</svg>
										</Avatar>
										<p class="mb-0 text-bold">{{ option.title }}</p>
									</div>
									<p v-if="selectedContract" class="mb-0">
										<b>
											{{
												formatCurrency(
													(option.yearPrice / 12) * selectedContract?.remainingMonths
												)
											}}
										</b>
										<span class="ml-1">
											(
											{{
												$t("myCadac.supportOverview.autocomplete.yearPrice", {
													price: formatCurrency(option.yearPrice),
												})
											}}
											)
										</span>
									</p>
									<b v-else class="mb-0">
										{{
											$t("myCadac.supportOverview.autocomplete.yearPrice", {
												price: formatCurrency(option.yearPrice),
											})
										}}
									</b>
								</div>
							</template>
						</AutoComplete>
						<Button class="p-button-primary" icon="pi pi-search" />
					</div>
					<Message v-if="hasNoSearchResults" :closable="false" severity="warn">
						{{ $t("myCadac.supportOverview.autocomplete.msgNoResults", { query: searchQuery }) }}
					</Message>
					<template #error>
						<Message
							v-if="
								!supportProductValidation.get(SupportValidationEnum.AtleastOneAsset) && submitted
							"
							:id="`validation-msg-${SupportValidationEnum.AtleastOneAsset}`"
							:closable="false"
							severity="error"
						>
							{{ $t("product.message.atleastOneAsset") }}
						</Message>
					</template>
				</ProductConfigurationStep>

				<ProductConfigurationStep
					v-if="vm.contractsForProduct?.length && !vm.supportContract"
					id="step-contract"
				>
					<template #header>
						<span>{{ $t("product.lblChooseContract") }}</span>
						<span>({{ $t("common.optional") }})</span>
						<p class="product-config-step-subtitle">{{ $t("product.lblChooseContract_subtitle") }}</p>
					</template>

					<DataTable
						v-if="vm.contractsForProduct?.length"
						v-model:selection="selectedContract"
						auto-layout
						data-key="contractNumber"
						:meta-key-selection="false"
						selection-mode="single"
						:value="vm.contractsForProduct"
					>
						<Column
							class="text-lg-center"
							selection-mode="single"
							style="width: 5%; padding: 1rem; overflow: hidden"
						/>
						<Column
							key="contractNumber"
							field="contractNumber"
							:header="$t('contract.contractNumber')"
						>
							<template #body="item">
								<p
									v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
									class="mb-0 text-bold"
								>
									<a
										href="javascript:void(0)"
										:title="$t('common.goToCrm')"
										@click="openUrl(item.data.crmUrl, '_blank')"
									>
										{{ item.data.contractNumber }}
									</a>
								</p>
								<p v-else class="mb-0 text-bold">
									{{ item.data.contractNumber }}
								</p>
							</template>
						</Column>
						<Column v-if="!isSmallScreen" key="products" :header="$t('contract.assets')">
							<template #body="item">
								<AvatarGroup>
									<Avatar
										v-for="(asset, index) in item.data.contractAssets?.slice(
											0,
											maxContractAssetsToShow
										)"
										:key="index"
										:alt="asset.iconAlt"
										class="ml-0 bg-transparent"
										:image="asset.icon"
										shape="square"
										size="small"
										:title="asset.productName"
									>
									</Avatar>
									<Avatar
										v-if="item.data.contractAssets?.length > maxContractAssetsToShow"
										class="text-bold ml-0"
										:label="`+${item.data.contractAssets?.length - maxContractAssetsToShow}`"
										shape="square"
										size="small"
									>
									</Avatar>
								</AvatarGroup>
							</template>
						</Column>
						<Column key="endDate" field="endDate" :header="$t('contract.endDate')">
							<template #body="item">
								<span>
									{{ formatDate(item.data.endDate) }}
								</span>
							</template>
						</Column>
						<Column key="remainingTerm" :header="$t('contract.remainingDuration')">
							<template #body="item">
								<span>
									{{ $tc("common.months", item.data.remainingMonths) }}
								</span>
							</template>
						</Column>
					</DataTable>
				</ProductConfigurationStep>
			</ProductConfiguration>
			<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
				<h3 class="px-2">{{ $t("product.titleUpSell") }}</h3>
				<div class="d-flex">
					<div v-for="product in vm.relatedUpSellProducts" :key="product">
						<ProductOverviewItemCard :item="product" layout="grid" />
					</div>
				</div>
			</ProductPageContentSection>
		</template>
		<ProductPageContentSection v-if="$slots.blocks" :type="'content'">
			<slot name="blocks" />
		</ProductPageContentSection>
		<template #summary>
			<ProductPageSummary
				:can-submit="!submitted || !validateProductConfiguration()"
				:is-product-available="vm.viewModelIsValid"
				:total-price="priceSummary?.totalPrice"
				@addToCart="addSupportProductToCart()"
			>
				<template #priceSummary>
					<PriceSummary :vm="priceSummary" />
					<p class="text-muted lead text-right">{{ $t("common.productSummary.allPricesExclVat") }}</p>
				</template>
				<template #configurationSummary>
					<ProductConfigurationSummary :header="$t('product.titleSummarySupport')">
						<ProductConfigurationSummaryItem
							:content="
								$tc(
									`common.months`,
									vm.supportContract?.remainingMonths || selectedContract?.remainingMonths || 12
								)
							"
							:label="$t('myCadac.contractDetail.table.colDuration')"
						/>
						<ProductConfigurationSummaryItem :label="$t('cart.table.colSoftware')">
							<template #content>
								<div v-if="selectedProducts?.length">
									<AvatarGroup>
										<Avatar
											v-for="(product, index) in selectedProducts"
											:key="index"
											:alt="product.iconAlt"
											class="bg-transparent"
											:image="product.icon"
											shape="square"
											size="large"
											:title="product.title"
										/>
									</AvatarGroup>
								</div>
								<span v-else>{{ $t("common.none") }}</span>
							</template>
						</ProductConfigurationSummaryItem>
					</ProductConfigurationSummary>
				</template>
				<template #validationMessage>
					<Message
						v-if="validationErrors.length && submitted"
						:closable="false"
						severity="warn"
						style="cursor: pointer"
						@click="scrollToFirstValidationError()"
					>
						{{ $tc("common.productSummary.msgValidationErrors", validationErrors.length) }}
					</Message>
				</template>
			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import ProductLayout from "../layout/productLayout.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
import ProductPageHeader from "@/components/product/productPageHeader.vue";
import ProductPageSummary from "@/components/product/productPageSummary.vue";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { Contract } from "@/types/generated/contract";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ISupportProduct } from "@/types/viewModels/commerce/supportProductViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SupportAddToCart } from "@/types/generated/Api/supportAddToCart";
import { SupportProductValidation } from "@/types/enum/SupportProductValidation";
import { SupportVariationViewModel } from "@/types/models/support/supportVariationViewModel";
import { useCartStore } from "@/store/commerce/cartStore";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({
	components: {
		AutoComplete,
		PopoverIcon,
		PriceSummary,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationStep,
		ProductConfigurationSummary,
		ProductConfigurationSummaryItem,
		ProductLayout,
		ProductOverviewItemCard,
		ProductPageContentSection,
		ProductPageHeader,
		ProductPageSummary,
		ProgressSpinner,
		ScrollPanel,
	},
})
export default class SupportProduct extends BaseComponent {
	@Prop({ type: Object as PropType<ISupportProduct>, required: true, default: {} }) vm!: ISupportProduct;

	searchQuery = "";
	submitted = false;
	hasNoSearchResults = false;
	maxContractAssetsToShow = 3;

	searchProductResults: SupportVariationViewModel[] = [];
	selectedContract!: Contract;
	selectedProducts: SupportVariationViewModel[] = [];
	selectedSupportContractAsset!: SupportVariationViewModel;

	supportProductValidation: Map<SupportProductValidation, boolean> = new Map<
		SupportProductValidation,
		boolean
	>();
	priceSummary!: IPriceSummary;

	cartStore = useCartStore();

	created(): void {
		this.selectedProducts = this.vm.supportVariations;
		this.initFreshChatTopic(["product"]);
		this.setDataLayer.viewItem(
			toFixed(this.priceSummary?.totalPrice, 2),
			this.selectedProducts,
			null,
			this.vm.brand,
			this.vm.name,
			ProductCategoryType[this.vm.productCategory]
		);
	}

	@Watch("selectedContract") onContractChanged(): void {
		this.selectedProducts = this.mapRemainingPrice(this.selectedProducts);
		this.priceSummary = this.calculatePrice();
	}

	@Watch("selectedProducts") onProductsChanged(): void {
		this.priceSummary = this.calculatePrice();
	}

	get SupportValidationEnum(): typeof SupportProductValidation {
		return SupportProductValidation;
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Other,
			} as unknown as BaseProductItemViewModel,
			crossSellProducts: [],
		};
	}

	get addSupportRequestModel(): SupportAddToCart {
		return {
			contractNumber: this.selectedContract?.contractNumber,
			supportVariationCodes: this.selectedProducts?.map((x) => x.id),
		};
	}

	get productsToAdd(): SupportVariationViewModel[] {
		return this.selectedProducts.filter((x) => !x.addedToCart);
	}

	get validationErrors(): boolean[] {
		return [...this.supportProductValidation.values()].filter((x) => !x);
	}

	get totalPrice(): number {
		return this.selectedProducts.reduce((prev, cur) => {
			if (this.layoutStore.isAuthenticated && cur.remainingPrice) {
				return prev + cur.remainingPrice;
			} else {
				return prev + (cur.yearPrice || 0);
			}
		}, 0);
	}

	get calcRemainingPrice(): (product: SupportVariationViewModel) => number {
		return (product: SupportVariationViewModel) =>
			((product.yearPrice || 0) / 12) * this.selectedContract?.remainingMonths;
	}

	calculatePrice(): IPriceSummary {
		const standardLines = new Map<string, number>();
		this.selectedProducts?.forEach((x) => {
			standardLines.set(x.title, x.remainingPrice || x.yearPrice || 0);
		});
		return {
			totalPrice: this.totalPrice,
			standardLines,
		} as IPriceSummary;
	}

	mapRemainingPrice(products: SupportVariationViewModel[]): SupportVariationViewModel[] {
		products.forEach((prod) => {
			prod.remainingPrice = this.calcRemainingPrice(prod);
		});
		return products;
	}

	hideProductAddedModal(): void {
		this.cartStore.hideModal("ProductAdded");
	}

	onSearchTrigger({ query }: { query: string }): void {
		this.searchQuery = query;
		const existingIds = [...this.selectedProducts, ...this.vm.supportContractSupportAssets]
			.map((x) => x.id)
			.join("|");
		this.axios
			.get(`/api/${this.vm.localizationPrefix}/support/searchvariations`, {
				params: { query, existingIds },
				requestId: "searchVariations",
			})
			.then(({ data }) => {
				this.searchProductResults = this.selectedContract
					? this.mapRemainingPrice(data.results)
					: data.results;
				this.hasNoSearchResults = data.results.length <= 0;
			})
			.catch((err) => {
				this.searchProductResults = [];
				this.hasNoSearchResults = true;
				Log.error(err);
			});
	}

	addSupportProductToCart(): void {
		this.submitted = true;
		if (!this.validateProductConfiguration()) {
			setTimeout(() => this.scrollToFirstValidationError(), 50);
			return;
		}
		this.loadingStore.increaseLoadingCount();

		this.axios
			.post(`/api/cart/add-support`, this.addSupportRequestModel)
			.then(() => {
				this.cartStore.showModal("ProductAdded");

				this.setDataLayer.addToCart(
					toFixed(this.priceSummary?.totalPrice, 2),
					this.selectedProducts,
					null,
					this.vm.brand
				);
			})
			.catch(() =>
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("common.messages.addToCartError"),
					life: 3000,
				})
			)
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private validateProductConfiguration(): boolean {
		this.supportProductValidation.set(
			SupportProductValidation.AtleastOneAsset,
			this.productsToAdd?.length > 0
		);
		return this.validationErrors.length === 0;
	}
}
</script>

<style scoped lang="scss">
.product-config-step-subtitle {
	font-size: 1rem;
	font-weight: 400;
	color: var(--text-color-secondary);
}
</style>
