<template>
	<ProductLayout v-model="showSideBar" :showTeachingsMaterialButton="true" :help-block="selectedHelpBlock" :vm="vm">
		<template #tabs>
			<ProductTabs v-if="vm.viewModelIsValid && vm.isBeNeMarket" :items="productTabs">
				<template #tabConfiguration>
					<ProductPageContentSection>
						<ProductConfiguration>
							<ProductConfigurationStep
								v-if="productStore.availableTrainingMethods?.length"
								id="step-type"
							>
								<template #header>
									{{ $t("training.lblType") }}
									<Button
										v-if="vm.helpBlockTrainingMethod"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockTrainingMethod?.linkText"
										@click="showHelpSideBar(vm.helpBlockTrainingMethod)"
									/>
								</template>
								<template #options>
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableTrainingMethods"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedTrainingMethod"
										:option="option"
										@option-selected="onSelectedTrainingMethodChanged(option.value)"
									>
									</ProductConfigurationOption>
									<div class="helper-text">
										<span class="">{{ trainingMethodHelpText }}</span>
									</div>
									<div
										v-if="
											productStore.selectedTrainingMethod ===
											TrainingMethodEnum.inCompanyModular
										"
										class="pt-2 w-100"
									>
										<Accordion
											:active-index="trainingModulesAccordionActiveIndexes"
											class="accordion-modules"
											:multiple="true"
										>
											<AccordionTab
												v-for="course in productStore.allTrainingCourses"
												:key="course"
											>
												<template #header>
													<div
														class="d-flex align-items-center justify-content-between w-100"
													>
														<div class="ml-1">
															{{ course.name }}
															<span class="font-weight-normal text-muted mx-1">
																({{
																	$tc(
																		"training.lblModuleCount",
																		selectedModulesForCourse(course).length
																	)
																}})
															</span>
														</div>
													</div>
												</template>
												<TrainingModuleTable
													:key="course"
													:is-editable="
														productStore.selectedTrainingConfigurationOption ===
														TrainingConfigurationOptionEnum.Custom
													"
													:training-course="course"
													@selected-modules-changed="onSelectedModulesChanged($event)"
												/>
											</AccordionTab>
										</Accordion>
										<Button
											class="mb-3 mb-1 w-100"
											:label="$t('training.button.addModule')"
											@click="onAddModuleClick()"
										/>
									</div>
								</template>
							</ProductConfigurationStep>
							<ProductConfigurationStep v-if="productStore.canChooseLocation" id="step-location">
								<template #error>
									<Message
										v-if="
											!trainingValidations.get(
												TrainingValidationEnum.ValidAddressOwnLocation
											) && submitted
										"
										:id="`validation-msg-${TrainingValidationEnum.ValidAddressOwnLocation}`"
										:closable="false"
										severity="error"
									>
										{{ $t("validations.address") }}
									</Message>
									<Message
										v-if="
											!trainingValidations.get(TrainingValidationEnum.ValidVdiChoice) &&
											submitted
										"
										:id="`validation-msg-${TrainingValidationEnum.ValidVdiChoice}`"
										:closable="false"
										severity="error"
									>
										{{ $t("validations.chooseTrainingHardware") }}
									</Message>
								</template>
								<template #header>
									{{ $t("training.lblLocation") }}
									<Button
										v-if="vm.helpBlockTrainingLocation"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockTrainingLocation?.linkText"
										@click="showHelpSideBar(vm.helpBlockTrainingLocation)"
									/>
								</template>
								<div v-if="productStore.availableTrainingLocationOptions?.length">
									<div class="input-group mb-3">
										<div
											v-for="(
												location, index
											) in productStore.getAvailableTrainingLocationOptions"
											:key="index"
											class="form-check-inline"
										>
											<RadioButton
												v-model="productStore.selectedTrainingLocationOption"
												:input-id="`location${index}`"
												name="location"
												:value="location.value"
												@change="onSelectedTrainingLocationOptionChanged(location.value)"
											/>
											<label class="ml-1 mb-0" :for="`location${index}`">{{
												location.label
											}}</label>
										</div>
									</div>

									<div
										v-if="
											productStore.selectedTrainingLocationOption ===
											TrainingLocationOptionEnum.Cadac
										"
										class="d-flex flex-wrap"
									>
										<ProductConfigurationOption
											v-for="(option, index) in productStore.availableTrainingLocations"
											:key="index"
											:disabled="isBusy"
											:is-selected="
												option.value?.id === productStore.selectedTrainingLocation?.id
											"
											:option="option"
											@option-selected="onSelectedTrainingLocationChanged(option.value)"
										>
										</ProductConfigurationOption>
									</div>
									<div
										v-else-if="
											productStore.selectedTrainingLocationOption ===
											TrainingLocationOptionEnum.OwnLocation
										"
									>
										<AddressForm
											v-model="productStore.trainingAddress"
											ref="trainingAddressForm"
											:countries="vm.countries"
										/>

										<div class="form-row">
											<div class="form-group col-md-12 col-lg-10 col-xxl-8">
												<label>
													{{ $t("training.lblUseCadacHardware") }}
												</label>
												<PopoverIcon class="d-inline-flex ml-0">
													<i class="popover-icon pi pi-question" />
													<template #content>
														<span
															v-html="
																vm.helpBlockTrainingHardware?.items
																	?.map((x) => x.text)
																	?.join('<br>')
															"
														/>
													</template>
												</PopoverIcon>

												<Dropdown
													v-model="selectedVdiChoice"
													option-label="label"
													option-value="value"
													:options="vdiTrainingOptions"
												/>
											</div>
										</div>
									</div>
									<div v-else>
										<div class="form-row">
											<div class="form-group col-md-12 col-lg-10 col-xxl-8">
												<label>
													{{ $t("training.lblUseCadacHardware") }}
												</label>
												<PopoverIcon class="d-inline-flex ml-0">
													<i class="popover-icon pi pi-question" />
													<template #content>
														<span
															v-html="
																vm.helpBlockTrainingHardware?.items
																	?.map((x) => x.text)
																	?.join('<br>')
															"
														/>
													</template>
												</PopoverIcon>
												<Dropdown
													v-model="selectedVdiChoice"
													option-label="label"
													option-value="value"
													:options="vdiTrainingOptions"
												/>
											</div>
										</div>
									</div>
								</div>
								<div v-else class="d-flex flex-wrap">
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableTrainingLocations"
										:key="index"
										:disabled="isBusy"
										:is-selected="
											option.value?.id === productStore.selectedTrainingLocation?.id
										"
										:option="option"
										@option-selected="onSelectedTrainingLocationChanged(option.value)"
									>
									</ProductConfigurationOption>
								</div>
							</ProductConfigurationStep>

							<ProductConfigurationStep
								v-if="
									productStore.selectedTrainingMethod === TrainingMethodEnum.training ||
									productStore.selectedTrainingMethod === TrainingMethodEnum.inCompany ||
									productStore.selectedTrainingMethod === TrainingMethodEnum.inCompanyModular
								"
								id="step-date"
							>
								<template #error>
									<Message
										v-if="
											!trainingValidations.get(
												TrainingValidationEnum.ValidPreferredStartDate
											) && submitted
										"
										:id="`validation-msg-${TrainingValidationEnum.ValidPreferredStartDate}`"
										:closable="false"
										severity="error"
									>
										{{ $t("training.message.selectPreferredDate") }}
									</Message>
								</template>
								<template #header>
									<span
										v-if="
											productStore.selectedTrainingMethod === TrainingMethodEnum.inCompany ||
											productStore.selectedTrainingMethod ===
												TrainingMethodEnum.inCompanyModular
										"
									>
										{{ $t("training.lblDate") }}
									</span>
									<span v-else>
										{{ $t("training.lblDates") }}
									</span>
								</template>
								<template
									v-if="
										productStore.selectedTrainingMethod === TrainingMethodEnum.inCompany ||
										productStore.selectedTrainingMethod === TrainingMethodEnum.inCompanyModular
									"
									#footer
								>
									{{ $t("training.lblEndDatePreference") }}
								</template>

								<template
									v-if="productStore.selectedTrainingMethod === TrainingMethodEnum.training"
									#options
								>
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableScheduledTrainings"
										:key="index"
										:disabled="isBusy"
										:is-selected="
											option.value?.id === productStore.selectedScheduledTraining?.id
										"
										:option="option"
										@option-selected="onSelectedScheduledTrainingChanged(option.value)"
									>
										<template #usp-availablePlaces>
											<span v-if="option.value.availablePlaces">
												<i class="pi pi-user text-primary mr-1" />
												{{
													$tc("training.lblAvailableSeats", option.value.availablePlaces)
												}}
											</span>
										</template>
										<template #usp-isGuaranteed>
											<span v-if="option.value.guaranteed" class="text-success">
												<i class="pi pi-check-circle mr-1" />
												{{ $t("training.lblGuaranteed") }}
											</span>
										</template>
									</ProductConfigurationOption>
								</template>
								<template v-else #options>
									<Calendar
										v-model="selectedPreferredStartDate"
										:locale="currentRegion"
										v-bind="preferredDateOptions"
									/>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep id="step-participants">
								<template #error>
									<Message
										v-if="
											!trainingValidations.get(TrainingValidationEnum.MaxParticipants) &&
											submitted
										"
										:id="`validation-msg-${TrainingValidationEnum.MaxParticipants}`"
										:closable="false"
										severity="error"
									>
										{{
											$t("training.message.tooManyParticipants", [
												productStore.maxParticipants,
											])
										}}
									</Message>
									<Message
										v-if="
											!trainingValidations.get(
												TrainingValidationEnum.AtleastOneParticipant
											) && submitted
										"
										:id="`validation-msg-${TrainingValidationEnum.AtleastOneParticipant}`"
										:closable="false"
										severity="error"
									>
										{{ $t("training.message.atLeastOneParticipant") }}
									</Message>
									<Message
										v-if="
											!trainingValidations.get(
												TrainingValidationEnum.UniqueEmailParticipant
											) && submitted
										"
										:id="`validation-msg-${TrainingValidationEnum.UniqueEmailParticipant}`"
										:closable="false"
										severity="error"
									>
										{{ $t("training.message.uniqueEmailParticipant") }}
									</Message>
								</template>
								<template #header>
									{{ $t("training.lblParticipants") }}
									<Button
										v-if="vm.helpBlockTrainingParticipants"
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockTrainingParticipants?.linkText"
										@click="showHelpSideBar(vm.helpBlockTrainingParticipants)"
									/>
								</template>
								<div class="mb-3 pr-lg-5 pr-md-2">
									<Accordion
										v-model:active-index="trainingParticipantsAccordionActiveIndexes"
										ref="productStore.trainingParticipantsAccordion"
										:multiple="true"
									>
										<AccordionTab
											v-for="(participant, index) in productStore.trainingParticipants"
											:key="participant"
										>
											<template #header>
												<div
													class="d-flex justify-content-between align-items-center w-100"
												>
													<div class="d-flex align-items-center">
														<h5 class="mx-2 mb-0">{{ index + 1 }}.</h5>
														<p class="mr-1 mb-0">
															{{ getParticipantName(participant) }}
														</p>
													</div>
													<Button
														class="p-button-danger p-button-outlined p-button-rounded border"
														icon="pi pi-times"
														@click.stop="removeParticipant(index)"
													/>
												</div>
											</template>
											<small>{{ $t("training.lblOptionalFieldsIfUnknown") }}</small>
											<TrainingParticipantForm
												class="mt-1"
												:index="index"
												:item="participant"
												:submitted="submitted"
											/>
										</AccordionTab>
									</Accordion>
									<div v-if="productStore.canAddParticipants" class="mt-2">
										<Button
											class="p-button-text mr-2 px-0"
											icon="pi pi-plus-circle p-button-icon-lg"
											:label="$t('training.button.addParticipant')"
											@click="addParticipant()"
										/>
										<Button
											v-if="
												vm.loggedInUserAsParticipant &&
												!productStore.trainingParticipants.find(
													(x) => x.id === vm.loggedInUserAsParticipant.id
												)
											"
											class="p-button-text p-button-secondary px-0"
											icon="pi pi-user-plus p-button-icon-lg"
											:label="$t('training.button.addSelfAsParticipant')"
											@click="addParticipant(vm.loggedInUserAsParticipant)"
										/>
									</div>
								</div>
							</ProductConfigurationStep>
						</ProductConfiguration>
					</ProductPageContentSection>
					<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
						<h3 class="">{{ $t("product.titleUpSell") }}</h3>
						<div class="row">
							<div v-for="product in vm.relatedUpSellProducts" :key="product" class="col-md-6 mb-2">
								<BaseProductOverviewItemCard is-cross-sell :vm="product" />
							</div>
						</div>
					</ProductPageContentSection>
				</template>
			</ProductTabs>
		</template>
		<template #summary>
			<ProductPageSummary :can-submit="!submitted || validateProductConfiguration()"
								:is-product-available="vm.viewModelIsValid && vm.isBeNeMarket"
								:total-price="trainingPriceCalculation?.totalPrice"
								@addToCart="addToCart()">

				<Card class="product-config-cta-card" v-if="!vm.isBeNeMarket">
					<template #content>
						<h3 class="mb-1">{{ vm.name }}</h3>
						<p v-if="!isMobile" class="lead">
							{{ $t('training.onrequest.text') }}
						</p>
						<LinkButton v-if="vm.contactPageUrl"
									class="p-button-success p-button-arrow p-button-raised mt-0 mt-sm-2"
									:label="$t('training.onrequest.button')"
									:url="vm.contactPageUrl" />
					</template>
				</Card>
				<template #priceSummary>
					<ScrollPanel style="width: 100%; height: clamp(200px, 30vh, 300px)">
						<TrainingPriceSummary :vm="trainingPriceCalculation" />
					</ScrollPanel>
				</template>
				<template #configurationSummary>
					<ProductConfigurationSummary :header="$t('training.titleSummary')">
						<ProductConfigurationSummaryItem :content="$t(`enum.trainingMethod.${productStore.selectedTrainingMethod}`)"
														 :label="$t('training.lblType')" />
						<ProductConfigurationSummaryItem v-if="productStore.selectedTrainingMethod === 3"
														 :label="$t('training.lblModular')">
							<template #content>
								<ColumnYesNo :value="
										productStore.selectedTrainingConfigurationOption ===
										TrainingConfigurationOptionEnum.Custom
									" />
							</template>
						</ProductConfigurationSummaryItem>
						<ProductConfigurationSummaryItem :content="selectedLocationText"
														 :label="$t('training.lblLocation')" />
						<ProductConfigurationSummaryItem v-if="productStore.trainingProductForm?.isVdiTraining"
														 :label="$t('training.lblVirtualDesktop')">
							<template #content>
								<ColumnYesNo :value="productStore.trainingProductForm.isVdiTraining" />
							</template>
						</ProductConfigurationSummaryItem>
						<ProductConfigurationSummaryItem :content="$n(trainingPriceCalculation?.totalDurationInDays || 0).toString()"
														 :label="$t('training.lblDuration')" />
						<ProductConfigurationSummaryItem :content="selectedDateFormatted || '-'"
														 :label="$t('training.lblDate')" />
						<ProductConfigurationSummaryItem :content="$n(productStore.trainingParticipants?.length || 0).toString()"
														 :label="$t('training.lblNumberOfParticipants')" />
					</ProductConfigurationSummary>
				</template>
				<template #validationMessage>
					<Message v-if="validationErrors.length && submitted"
							 :closable="false"
							 severity="warn"
							 style="cursor: pointer"
							 @click="scrollToFirstValidationError()">
						{{ $tc("common.productSummary.msgValidationErrors", validationErrors.length) }}
					</Message>
				</template>
				<template #walletMessage>
					<WalletMessage v-show="!isSmallScreen && vm?.canUseWallet"
								   :wallet-amount="trainingPriceCalculation?.walletAmount" />
				</template>
			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" @close="refresh" />
	<TrainingAddCourseModal
		v-if="trainingStore.isTrainingAddCourseModalVisible"
		:courses="vm.relatedProductModules"
		@training-course-added="onTrainingCourseAdded($event)"
	/>
</template>

<script lang="ts">
// @ts-nocheck
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AddressForm from "@/components/common/forms/addressForm.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import Calendar, { CalendarProps } from "primevue/calendar";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import ColumnYesNo from "@/components/commerce/cart/columnYesNo.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationOption from "@/components/product/productConfigurationOption.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import ProductLayout from "@/views/layout/productLayout.vue";
import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
import ProductPageHeader from "@/components/product/productPageHeader.vue";
import ProductPageSummary from "@/components/product/productPageSummary.vue";
import ProductTabs, { ProductTab } from "@/components/product/productTabs.vue";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";
import TrainingAddCourseModal, {
	TrainingCourseAddedEvent,
} from "@/components/training/trainingAddCourseModal.vue";
import TrainingCourseTable from "@/components/training/trainingCourseTable.vue";
import TrainingModuleTable, { TrainingModulesChangedEvent } from "@/components/training/trainingModuleTable.vue";
import TrainingParticipantForm from "@/components/common/forms/trainingParticipantForm.vue";
import TrainingPriceSummary from "@/components/training/trainingPriceSummary.vue";
import WalletMessage from "@/components/common/WalletMessage.vue";
import { AddressForm as AddressFormModel } from "@/types/models/form/addressForm";
import { Component, Prop, Ref, Watch } from "vue-facing-decorator";
import { HelpBlockViewModel } from "@/types/generated/Common/helpBlockViewModel";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { LocalTrainingType } from "@/types/enum/localTrainingType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { ScheduledTrainingViewModel } from "@/types/generated/Training/scheduledTrainingViewModel";
import { TrainingCalculationErrorCode } from "@/types/enum/trainingCalculationErrorCode";
import { TrainingCalculationRequest } from "@/types/generated/Api/trainingCalculationRequest";
import { TrainingCalculationResponse } from "@/types/generated/Api/trainingCalculationResponse";
import { TrainingConfigurationOption } from "@/types/enum/trainingConfigurationOption";
import { TrainingLocationOption } from "@/types/enum/trainingLocationOption";
import { TrainingLocationViewModel } from "@/types/models/training/trainingLocationViewModel";
import { TrainingMethod } from "@/types/enum/trainingMethod";
import { TrainingParticipantForm as TrainingParticipantFormModel } from "@/types/models/form/trainingParticipantForm";
import { TrainingProductAddToCartRequest } from "@/types/generated/Api/trainingProductAddToCartRequest";
import { TrainingProductGroupItemViewModel } from "@/types/models/training/trainingProductGroupItemViewModel";
import { TrainingValidation } from "@/types/enum/trainingValidation";
import { TrainingViewModel } from "@/types/generated/Training/trainingViewModel";
import { addBusinessDays, isValid } from "date-fns";
import { cloneDeep } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";
import { useTrainingProductStore } from "@/store/training/trainingProductStore";
import { useTrainingStore } from "@/store/training/trainingStore";
import { zonedTimeToUtc } from "date-fns-tz";
import { FormComponent } from "@/types/models/form/formComponent";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import toFixed from "accounting-js/lib/toFixed.js";
import { ITrainingProduct } from "../../types/viewModels/commerce/trainingProductViewModel";

@Component({
	components: {
		Accordion,
		AccordionTab,
		AddressForm,
		BaseProductOverviewItemCard,
		Calendar,
		Checkbox,
		ColumnYesNo,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationOption,
		ProductConfigurationStep,
		ProductConfigurationSummary,
		ProductConfigurationSummaryItem,
		ProductLayout,
		ProductPageContentSection,
		ProductPageHeader,
		ProductPageSummary,
		ProductTabs,
		ProgressSpinner,
		ScrollPanel,
		TrainingAddCourseModal,
		TrainingCourseTable,
		TrainingModuleTable,
		TrainingParticipantForm,
		TrainingPriceSummary,
		WalletMessage,
        Card,
	},
})
export default class TrainingProduct extends BaseComponent {
	@Prop({ type: Object as PropType<ITrainingProduct>, required: true, default: {} })
	vm!: TrainingViewModel;

	////////////////////////MISSING  TRAININGS PROP??????
	@Ref readonly trainingAddressForm!: FormComponent<AddressFormModel>;
	submitted = false;
	showSideBar = false;
	trainingParticipantsAccordionActiveIndexes: number[] = [];
	trainingModulesAccordionActiveIndexes: number[] = [];
	selectedHelpBlock!: HelpBlockViewModel | null;
	selectedPreferredStartDate!: Date;
	selectedVdiChoice: boolean | null = null;
	vdiTrainingOptions: IDropdownItem[] = [];
	trainingPriceCalculation: TrainingCalculationResponse | null = null;
	trainingValidations: Map<TrainingValidation, boolean> = new Map<TrainingValidation, boolean>();

	cartStore = useCartStore();
	productStore = useTrainingProductStore();
	trainingStore = useTrainingStore();

	@Watch("productStore.trainingAddress", { deep: true }) onTrainingAddressChanged(): void {
		this.trainingValidations.set(
			TrainingValidation.ValidAddressOwnLocation,
			this.productStore.selectedTrainingLocationOption === TrainingLocationOption.OwnLocation
				? this.trainingAddressForm?.isValid || false
				: true
		);
	}

	@Watch("productStore.trainingParticipants", { deep: true })
	onTrainingParticipantsChanged(): void {
		this.trainingValidations.set(TrainingValidation.UniqueEmailParticipant, this.checkParticipantsUniqueEmail);
		this.trainingValidations.set(TrainingValidation.MaxParticipants, this.checkMaxParticipants);
	}

	@Watch("selectedVdiChoice", { deep: true })
	onSelectedVdiChoiceChanged(): void {
		this.recalculatePrice();
	}

	created(): void {
		this.loadingStore.increaseLoadingCount();
		this.initFreshChatTopic(["product"]);
		const { trainingProductConfiguration, viewModelIsValid, viewModelErrors } = this.vm;
		if (viewModelIsValid) {
			Log.info("TrainingProduct", this.vm);
			this.productStore.isInitializing = true;
			this.productStore.initProductPage(this.vm);

			if (trainingProductConfiguration) {
				this.selectedVdiChoice = trainingProductConfiguration.isVdiTraining;
				this.productStore.initPreviousConfiguration(trainingProductConfiguration);
				setTimeout(
					() =>
						(this.selectedPreferredStartDate = this.toDate(
							trainingProductConfiguration.desiredDate as Date
						))
				);
			}
		} else {
			Log.error(new Error(viewModelErrors?.join(", ")));
		}
		this.vdiTrainingOptions = [
			{ label: `${this.$t("training.lblVdiYes")}`, value: true },
			{ label: `${this.$t("training.lblVdiNo")}`, value: false },
		];

		this.trainingModulesAccordionActiveIndexes = this.productStore.allTrainingCourses.map((_, i) => i);
		this.productStore.isInitializing = false;
		this.loadingStore.decreaseLoadingCount();
		this.validateProductConfiguration();
		this.recalculatePrice();
	}

	mounted(): void {
		this.recalculatePrice(() => this.sendViewItem());
	}

	get TrainingValidationEnum(): typeof TrainingValidation {
		return TrainingValidation;
	}

	get TrainingLocationOptionEnum(): typeof TrainingLocationOption {
		return TrainingLocationOption;
	}

	get TrainingConfigurationOptionEnum(): typeof TrainingConfigurationOption {
		return TrainingConfigurationOption;
	}

	get TrainingMethodEnum(): typeof TrainingMethod {
		return TrainingMethod;
	}

	get trainingMethodHelpText(): string {
		switch (this.productStore.selectedTrainingMethod) {
			case TrainingMethod.training:
				return this.$t("training.lblTrainingMethodClassWithOthers");
			case TrainingMethod.inCompany:
			case TrainingMethod.inCompanyModular:
				return this.$t("training.lblTrainingMethodMaxNumberOfColleagues", [
					this.productStore.maxParticipants,
				]);
			case TrainingMethod.elearning:
				return this.$t("training.lblTrainingMethodElearning");
			default:
				return "";
		}
	}

	get selectedLocationText(): string {
		return this.productStore.selectedTrainingLocationOption === TrainingLocationOption.Cadac
			? this.productStore.selectedTrainingLocation?.name ||
					this.$t(`enum.trainingLocation.${this.productStore.selectedTrainingLocationOption}`)
			: this.productStore.selectedTrainingLocationOption === TrainingLocationOption.OnlineRemote
				? this.$t(`enum.trainingLocation.${TrainingLocationOption.OnlineRemote}`)
				: this.$t(`enum.trainingLocation.${TrainingLocationOption.OwnLocation}`);
	}

	get selectedModulesForCourse() {
		return (course: TrainingViewModel) =>
			course.modules.filter((x) => this.productStore.isModuleSelected(course.cadacIdentifier, x.sku));
	}

	get scheduledTrainingsForSelectedLocation() {
		const filteredScheduledTrainings =
			this.productStore.selectedTrainingCourse?.scheduledTrainings?.filter(
				(x) => x.location.id === this.productStore.selectedTrainingLocation?.id
			) || [];
		this.productStore.selectedScheduledTraining = filteredScheduledTrainings[0];
		return filteredScheduledTrainings;
	}

	get selectedDateFormatted(): string {
		switch (this.productStore.selectedTrainingMethod) {
			case TrainingMethod.inCompany:
			case TrainingMethod.inCompanyModular:
				return this.selectedPreferredStartDate ? this.formatDate(this.selectedPreferredStartDate) : "";
			case TrainingMethod.training:
				return this.productStore.selectedScheduledTraining
					? this.productStore.selectedScheduledTraining.datesString
					: "";
			default:
				return "";
		}
	}

	get productTabs(): ProductTab[] {
		return [
			{
				label: this.$t("training.tabComposeTraining"),
				id: "tabConfiguration",
			},
		];
	}

	get trainingAddToCartModel(): TrainingProductAddToCartRequest {
		const form = cloneDeep(this.productStore.trainingProductForm as TrainingProductAddToCartRequest);
		if (!form || !form.course) return {} as TrainingProductAddToCartRequest;
		if (this.selectedPreferredStartDate)
			form.desiredDate = zonedTimeToUtc(this.selectedPreferredStartDate, "UTC");
		form.type = this.productStore.trainingTypeForCurrentConfiguration;
		form.isVdiTraining = !!this.selectedVdiChoice;

		switch (this.productStore.selectedTrainingMethod) {
			case TrainingMethod.training:
				form.trainingLocationId = this.productStore.selectedScheduledTraining?.location?.id;
				form.trainingListId = this.productStore.selectedScheduledTraining?.id;
				delete form.desiredDate;
				break;
			case TrainingMethod.inCompany:
			case TrainingMethod.inCompanyModular:
				form.trainingLocationId =
					this.productStore.selectedTrainingLocationOption !== TrainingLocationOption.OwnLocation
						? this.productStore.selectedTrainingLocation?.id
						: "";

				break;
			case TrainingMethod.elearning:
			default:
				break;
		}
		if (this.productStore.trainingTypeForCurrentConfiguration !== LocalTrainingType.modular) {
			form.course.selectedModuleSkus = [];
		}
		form.trainingProductGroupId = this.vm.trainingProductGroupId;
		form.course.participants = this.productStore.trainingParticipants;
		form.additionalCourses.forEach((x) => (x.participants = this.productStore.trainingParticipants));
		form.allCourses = [form.course, ...form.additionalCourses];
		form.address = this.productStore.trainingAddress as AddressFormModel;
		return form;
	}

	get trainingCalculateModel(): TrainingCalculationRequest {
		if (!this.trainingAddToCartModel) return {} as TrainingCalculationRequest;
		const { course, isVdiTraining, additionalCourses, trainingLocationId } = this.trainingAddToCartModel;

		return {
			isVdiTraining,
			course,
			additionalCourses,
			locationId: trainingLocationId,
			allCourses: [course, ...(additionalCourses || [])],
			type: this.productStore.trainingTypeForCurrentConfiguration,
		};
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Training,
				subject: this.productStore.selectedTrainingCourse?.name,
			} as unknown as TrainingProductGroupItemViewModel,
			crossSellProducts: this.vm.relatedCrossSellProducts,
		};
	}

	get preferredDateOptions(): Partial<CalendarProps> {
		return {
			minDate: addBusinessDays(new Date(), 3),
			disabledDays: [0, 6],
			showIcon: true,
			numberOfMonths: 2,
			touchUI: this.isSmallScreen as any,
			responsiveOptions: [
				{ breakpoint: "1400px", numMonths: 2 },
				{ breakpoint: "1200px", numMonths: 1 },
			],
		};
	}

	get checkAtleastOneParticipant(): boolean {
		return this.productStore.trainingParticipants?.length > 0;
	}

	get checkMaxParticipants(): boolean {
		return this.productStore.trainingParticipants?.length <= this.productStore.maxParticipants;
	}

	get checkParticipantsUniqueEmail(): boolean {
		const nonEmptyEmails = this.productStore.trainingParticipants.map((x) => x.email).filter(Boolean);
		return new Set(nonEmptyEmails).size === nonEmptyEmails.length;
	}

	get isPreferredStartDateValid(): boolean {
		return (
			(this.productStore.selectedTrainingMethod === TrainingMethod.inCompany &&
				isValid(this.selectedPreferredStartDate)) ||
			(this.productStore.selectedTrainingMethod === TrainingMethod.inCompanyModular &&
				isValid(this.selectedPreferredStartDate))
		);
	}

	get validationErrors(): boolean[] {
		return [...this.trainingValidations.values()].filter((x) => !x);
	}

	sendViewItem() {
		this.setDataLayer.viewItem(
			this.trainingPriceCalculation?.totalPrice
				? toFixed(this.trainingPriceCalculation?.totalPrice, 2)
				: "0",
			[
				{
					id: this.productStore.selectedTrainingCourse?.id || "0000-0000-0000-0000",
					name: this.productStore.selectedTrainingCourse?.name,
					basePrice: this.trainingPriceCalculation?.totalPrice,
					personalPrice: this.trainingPriceCalculation?.totalPrice,
					productCategory: ProductCategoryType.TrainingProductGroup,
					brand: this.vm.brand,
					amount: 1,
				},
			]
		);
	}

	addParticipant(loggedInUserAsParticipant?: TrainingParticipantFormModel): void {
		const participant = loggedInUserAsParticipant ?? ({ isUnknown: true } as any);
		this.productStore.trainingParticipants.push(participant);
		this.trainingParticipantsAccordionActiveIndexes.push(this.productStore.trainingParticipants.length - 1);
		this.recalculatePrice();
	}

	removeParticipant(index: number): void {
		this.productStore.trainingParticipants.splice(index, 1);
		this.trainingModulesAccordionActiveIndexes.splice(
			this.trainingModulesAccordionActiveIndexes.findIndex((x) => x === index),
			1
		);
		this.recalculatePrice();
	}

	getParticipantName(trainingParticipant: TrainingParticipantFormModel): string {
		return trainingParticipant.isUnknown
			? this.$t("training.lblUnknownParticipant")
			: `${trainingParticipant.firstName} ${trainingParticipant.lastName} (${trainingParticipant.email})`;
	}

	showHelpSideBar(helpBlock: HelpBlockViewModel): void {
		this.selectedHelpBlock = helpBlock;
		this.showSideBar = true;
	}

	addToCart(): void {
		this.submitted = true;
		if (!this.validateProductConfiguration()) {
			setTimeout(() => this.scrollToFirstValidationError(), 50);
			return;
		}
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post("/api/cart/add-training", this.trainingAddToCartModel)
			.then(() => {
				useCartStore().showModal("ProductAdded");

				this.setDataLayer.addToCart(
					toFixed(this.trainingPriceCalculation?.totalPrice, 2),
					[
						{
							id: this.productStore.selectedTrainingCourse?.id || "0000-0000-0000-0000",
							name: this.productStore.selectedTrainingCourse?.name,
							productCategory: ProductCategoryType.TrainingProductGroup,
							basePrice: this.trainingPriceCalculation?.totalPrice || 0,
							personalPrice: this.trainingPriceCalculation?.totalPrice || 0,
							brand: this.vm.brand,
							amount: 1,
						},
					],
					"1",
					this.vm.brand
				);
			})
			.catch((err) => Log.error(err))
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	recalculatePrice(cb?: () => void) {
		this.loadingStore.showSpinner("priceSummary");
		this.axios
			.post("/api/cart/calculate-training", this.trainingCalculateModel, { requestId: "calculate-training" })
			.then((res) => {
				this.trainingPriceCalculation = res.data as TrainingCalculationResponse;
				this.loadingStore.hideSpinner("priceSummary");

				if (typeof cb === "function") {
					cb();
				}
			})
			.catch((err) => {
				if (!this.axios.isCancelled(err)) {
					this.loadingStore.hideSpinner("priceSummary");
					Log.error(err);
				}
			});
	}

	onAddModuleClick(): void {
		this.trainingStore.showModal("AddCourse");
	}

	onTrainingCourseAdded(event: TrainingCourseAddedEvent): void {
		this.trainingStore.hideModal("AddCourse");
		this.productStore.addAdditionalCourse({
			selectedModuleSkus: event.modules.map((x) => x.sku),
			id: event.course.cadacIdentifier,
			participants: [],
		});
		this.trainingModulesAccordionActiveIndexes.push(this.trainingModulesAccordionActiveIndexes.length);
		this.recalculatePrice();
	}

	onSelectedModulesChanged(_event: TrainingModulesChangedEvent): void {
		this.recalculatePrice();
	}

	onSelectedTrainingLocationOptionChanged(value: TrainingLocationOption): void {
		this.productStore.changeTrainingLocationOption(value);
		this.trainingValidations.set(
			TrainingValidation.ValidAddressOwnLocation,
			value === TrainingLocationOption.OwnLocation ? this.trainingAddressForm?.isValid : true
		);
		this.trainingValidations.set(
			TrainingValidation.ValidVdiChoice,
			(value === TrainingLocationOption.OwnLocation || value === TrainingLocationOption.OnlineRemote) &&
				this.selectedVdiChoice !== null
		);

		this.productStore.setSelectedTrainingType(this.productStore.trainingTypeForCurrentConfiguration);

		this.selectedVdiChoice = null;
		this.recalculatePrice();
	}

	onSelectedTrainingConfigurationOptionChanged(_value: TrainingConfigurationOption): void {
		this.productStore.setSelectedTrainingType(this.productStore.trainingTypeForCurrentConfiguration);
		this.recalculatePrice();
	}

	onSelectedTrainingMethodChanged(value: TrainingMethod): void {
		this.productStore.selectedTrainingMethod = value;
		this.productStore.setSelectedTrainingType(this.productStore.trainingTypeForCurrentConfiguration);
		this.productStore.setupConfigurationOptions();
		this.productStore.resetCourse();
		if (value !== TrainingMethod.training) {
			this.productStore.selectedScheduledTraining = null;
		}
		this.recalculatePrice();
	}

	onSelectedTrainingCourseChanged(value: TrainingViewModel): void {
		this.productStore.changeTrainingCourse(value);
		this.productStore.setSelectedTrainingType(this.productStore.trainingTypeForCurrentConfiguration);
		this.productStore.setupConfigurationOptions();
		this.trainingModulesAccordionActiveIndexes = [0];

		this.recalculatePrice(() => this.sendViewItem());
	}

	onSelectedTrainingLocationChanged(value: TrainingLocationViewModel): void {
		this.productStore.changeTrainingLocation(value);
	}

	onSelectedScheduledTrainingChanged(value: ScheduledTrainingViewModel): void {
		this.productStore.selectedScheduledTraining = value;
	}

	refresh(): void {
		window.location.reload();
	}

	private validateProductConfiguration(): boolean {
		this.trainingValidations.set(TrainingValidation.MaxParticipants, this.checkMaxParticipants);
		this.trainingValidations.set(TrainingValidation.AtleastOneParticipant, this.checkAtleastOneParticipant);
		this.trainingValidations.set(TrainingValidation.UniqueEmailParticipant, this.checkParticipantsUniqueEmail);
		this.trainingValidations.set(
			TrainingValidation.ValidPriceCalculation,
			this.trainingPriceCalculation?.errorCode === TrainingCalculationErrorCode.durationError
				? !!this.trainingPriceCalculation?.isValid
				: true
		);
		this.trainingValidations.set(
			TrainingValidation.ValidVdiChoice,
			this.productStore.selectedTrainingLocationOption === TrainingLocationOption.OwnLocation ||
				this.productStore.selectedTrainingLocationOption === TrainingLocationOption.OnlineRemote
				? this.selectedVdiChoice !== null
				: true
		);

		if (this.productStore.selectedTrainingLocationOption === TrainingLocationOption.OwnLocation) {
			this.trainingValidations.set(
				TrainingValidation.ValidAddressOwnLocation,
				this.trainingAddressForm?.isValid || false
			);
		} else {
			this.trainingValidations.set(TrainingValidation.ValidAddressOwnLocation, true);
		}

		if (
			this.productStore.selectedTrainingMethod === TrainingMethod.inCompany ||
			this.productStore.selectedTrainingMethod === TrainingMethod.inCompanyModular
		) {
			this.trainingValidations.set(
				TrainingValidation.ValidPreferredStartDate,
				this.isPreferredStartDateValid
			);
		} else {
			this.trainingValidations.set(TrainingValidation.ValidPreferredStartDate, true);
		}
		return this.validationErrors.length === 0;
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.product-config-cta-card) {
    margin-bottom: 1rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);

    .p-card-content {
        text-align: center;
    }

    @media (max-width: 576px) {
        .p-card-content {
            padding: 0;
        }
    }
}

::v-deep(.product-configuration-form) {
	.form-group + .form-group {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}
}

::v-deep(.p-card) {
	.p-card-content {
		padding: 0.5rem 0.25rem;
	}

	.p-card-footer {
		padding-top: 0.5rem;
	}
}

.product-specifications {
	padding-top: 2rem;
	padding-right: 1rem;
	background: var(--surface-b);
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 100%;
		width: 200%;
		height: 100%;
		background: var(--surface-b);
	}
}

.form-check-inline {
	label {
		font-size: 1.1rem;

		&:hover {
			cursor: pointer;
		}
	}
}

::v-deep(.accordion-modules.p-accordion) {
	.p-accordion-header .p-accordion-header-link {
		padding: 0.75rem 0.5rem;
	}

	.p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
		background: var(--surface-c);
	}

	.p-accordion-content {
		padding: 0;
	}
}

.header-explanation-text {
	font-size: 0.9rem !important;
}

    .helper-text {
		flex-basis: 100%;
        width: -moz-fit-content;
        width: fit-content;
        padding-top: 0.5rem;
        color: var(--text-color-secondary);
    }

</style>
