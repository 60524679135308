<script lang="ts">
import httpClient from "@/types/helpers/apiHelper";
import { AxiosInstance } from "axios";
import { ComponentBase, Vue } from "vue-facing-decorator";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { Ref } from "vue";
import { UrlHelper } from "@/types/helpers/urlHelper";
import { formatDistance, formatDistanceToNow, isDate, parseISO } from "date-fns";
import { useBreakpoints, useClipboard, useMediaQuery } from "@vueuse/core";
import { CadacUserProfileViewModel } from "@/types/models/common/cadacUserProfileViewModel";
import { useLayoutStore } from "@/store/common/layoutStore";
import { useLoadingStore } from "@/store/common/loadingStore";
import { zonedTimeToUtc } from "date-fns-tz";
import { pushToGaDataLayer } from "@/types/utils/ga4";
import { GaEvent } from "@/types/models/googleAnalytics/enum/gaEvent";
import { GenerateLead, ViewItem } from "@/types/models/googleAnalytics/events/eCommerce/eCommerceModel";
import { ProductVariation } from "@/types/models/software/productVariation";
import { CheckoutData } from "@/types/models/googleAnalytics/properties/checkoutData";
import { merge } from "lodash";
import { CustomerData } from "@/types/models/googleAnalytics/properties/customerData";
import { CustomizeProduct } from "@/types/models/googleAnalytics/events/custom/customModel";
import { FormData } from "@/types/models/googleAnalytics/properties/formData";
import { ProductData } from "@/types/models/googleAnalytics/properties/productData";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import { TrafficSource } from "@/types/models/googleAnalytics/enum/trafficSource";
import toFixed from "accounting-js/lib/toFixed.js";

export interface ItemsViewItemModel {
	sku?: string | number;
	id?: string | number;
	title?: string | null;
	name?: string;
	productCategory?: ProductCategoryType;
	personalPrice?: number;
	priceSubTotal?: number;
	basePrice?: number;
	priceBase?: number;
	discount?: string | number;
	brand?: string;
	amount?: number;
}

/// Base Component with generic functions that can be re-used by multiple components
@ComponentBase({ name: "BaseComponent" })
export default class BaseComponent extends Vue {
	//Common Stores used by most components:
	layoutStore = useLayoutStore();
	loadingStore = useLoadingStore();

	// These breakpoints are the same as configured in bootstrap.scss! Important to keep these in sync if there are changes.
	breakpoints = useBreakpoints({
		xs: 0,
		sm: 576,
		md: 768,
		lg: 992,
		xls: 1200,
		xl: 1440,
		xxl: 2140,
	});

	isPrintMediaQuery = useMediaQuery("(print)");
	isSmallScreen = this.breakpoints.smaller("lg");
	isMediumScreen = this.breakpoints.smaller("md");
	isSmallLargeScreen = this.breakpoints.between("xls", "xl");
	isLargeScreen = this.breakpoints.between("lg", "xl");
	isExtraLargeScreen = this.breakpoints.between("xl", "xxl");
	isMobile = this.breakpoints.smaller("sm");
	isTablet = this.breakpoints.between("sm", "lg");
	chatRetryCounter = 0;

	// Get custom axios instance
	get axios(): AxiosInstance {
		return httpClient;
	}

	get isPrintView(): Ref<boolean> {
		return this.isPrintMediaQuery;
	}

	get chatWidget() {
		return window.fcWidget;
	}

	get currentRegion(): string {
		return FormatHelper.formatRegion(this.layoutStore.vm?.currentCulture) || "";
	}

	get currentCurrency(): string {
		return this.layoutStore.vm?.currentCurrency || "";
	}

	get currentLanguage(): string {
		return this.layoutStore.vm?.currentLanguage?.key || "";
	}

	get currentLocation(): string {
		return this.layoutStore.vm?.currentLocation?.key || "";
	}

	get userProfile(): CadacUserProfileViewModel | null {
		return this.layoutStore.vm?.currentUser as CadacUserProfileViewModel;
	}

	get userSource(): TrafficSource {
		const email = this.layoutStore.vm?.currentUser.email;
		if (email) {
			const [, domain] = email.split("@");
			const isImpersonated = this.layoutStore.vm?.currentUser.isImpersonated;
			if (domain.toLowerCase() === "cadac.com" || domain?.toLowerCase() === "cadac.nl" || isImpersonated)
				return TrafficSource.CadacInternal;
			else if (this.layoutStore.vm?.currentUser.isExternalSales) return TrafficSource.CadacExternal;
			else return TrafficSource.General;
		} else return TrafficSource.General;
	}

	get isSpinnerVisible(): (key: string) => boolean {
		return (key: string) => this.loadingStore.isSpinnerVisible(key);
	}

	get isBusy(): boolean {
		return this.loadingStore.isBusy;
	}

	get loginPageUrl(): string {
        return `${this.layoutStore.vm.loginPageUrl}/cadacstore?login_origin=${window.location.origin}&language=${this.currentLanguage}&redirect=${window.location}`.replace(/\s{2,}/g, " ").trim();
	}

	get registerPageUrl(): string {
        return `${this.layoutStore.vm.loginPageUrl}/cadacstore?login_origin=${window.location.origin}&createaccount=1&language=${this.currentLanguage}&redirect=${window.location}`.replace(/\s{2,}/g, " ").trim();
	}

	toDate(date: string | Date): Date {
		return FormatHelper.toDate(typeof date === "string" ? parseISO(date) : date);
	}

	toDateUtc(date: Date): Date {
		return zonedTimeToUtc(date, "UTC");
	}

	formatDate(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return FormatHelper.formatDateString(dateToFormat, this.currentRegion);
	}

	formatLongDate(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return FormatHelper.formatLongDateString(dateToFormat, this.currentRegion);
	}

	formatShortDate(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return FormatHelper.formatDateAsMonthYearString(dateToFormat, this.currentRegion);
	}

	formatDateTime(date: string | Date | undefined): string {
		const dateTimeToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return FormatHelper.formatDateTimeString(dateTimeToFormat, this.currentRegion?.toLowerCase());
	}

	formatTime(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return FormatHelper.formatTimeString(dateToFormat, this.currentRegion);
	}

	formatRemainingTime(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return formatDistanceToNow(Date.parse(dateToFormat), {
			locale: FormatHelper.dateLocales[this.currentRegion],
		});
	}

	formatElapsedTime(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return formatDistance(Date.parse(dateToFormat), new Date(), {
			locale: FormatHelper.dateLocales[this.currentRegion],
			addSuffix: true,
		});
	}

	formatProductName(productName: string, productBrand: string): string {
		return FormatHelper.productNameWithoutBrand(productName, productBrand);
	}

	formatCurrency(amount: number): string {
		const currency = this.$n(amount || 0, "currency", this.currentRegion);
		return currency.replace(/[,|.]00$/, ",-");
	}

	openUrl(url: string, target?: string): void {
		return UrlHelper.open(url, target);
	}

	copyToClipboard(text: string): Promise<void> {
		const clipBoard = useClipboard();
		return clipBoard.copy(text);
	}

	scrollToFirstValidationError(): void {
		const element = document.querySelector(`[id^="validation-msg-"]`)?.closest(".product-config-step");
		if (!element) return;
		window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY - 80, behavior: "smooth" });
	}

	toSnakeCase(str: string | null | undefined, convertCamelCase = true): string | null {
		if (!str) return null;
		if (!convertCamelCase) {
			return str
				.trim()
				.replace(/\s+/g, "_") // Replace whitespace with underscore
				.toLowerCase(); // Convert to lowercase
		}

		return str
			.trim()
			.replace(/\s+/g, "_") // Replace whitespace with underscore
			.replace(/([a-z])([A-Z])/g, "$1_$2") // Convert camelCase to snake_case
			.toLowerCase(); // Convert to lowercase
	}

	private openChatTopic(topic: string[]) {
		window.fcWidget.setTags(topic);
		if (window.fcWidget.isOpen() != true) {
			window.fcWidget.open();
		}
	}

	initFreshChatTopic(topic: string[]) {
		window.setTimeout(() => {
			if (window.fcWidget && window.fcWidget.isInitialized() == true) {
				this.openChatTopic(topic);
				this.chatRetryCounter = 0;
			} else {
				if (this.chatRetryCounter < 3) {
					this.chatRetryCounter++;
					this.initFreshChatTopic(topic);
				}
			}
		}, 5000);
	}

	constructECommerceDataLayer(
		event: GaEvent,
		value: string | undefined,
		items: ItemsViewItemModel[] | [],
		amount?: string | null,
		brand?: string,
		name?: string | null,
		category?: string | null
	): ViewItem {
		return {
			event: event,
			value: value || null,
			currency: this.currentCurrency.toLowerCase(),
			items: this.constructProductDataLayer(items, brand, name, amount, category),
			customer: this.constructCustomerData(this.userProfile),
		};
	}

	constructProductDataLayer(
		items: ItemsViewItemModel[] | [],
		brand?: string,
		name?: string | null,
		amount?: string | null,
		category?: string | null
	): ProductData[] {
		return items.map((item: ItemsViewItemModel) => {
			const basePrice = item.basePrice || item.priceBase || 0;
			const personalPrice = item.personalPrice || item.priceSubTotal || 0;
			const id = (item.sku || item.id)?.toString() || null;

			return {
				item_id: id,
				item_name: this.toSnakeCase(name || item.name || id),
				item_category:
					(item.productCategory && this.toSnakeCase(ProductCategoryType[item.productCategory])) ||
					this.toSnakeCase(category) ||
					null,
				price: this.layoutStore.isAuthenticated
					? toFixed(personalPrice, 2) || null
					: toFixed(basePrice, 2) || null,
				discount: item.discount
					? toFixed(item.discount, 2)
					: this.layoutStore.isAuthenticated
						? toFixed(basePrice - personalPrice, 2) || "0"
						: "0",
				item_brand: this.toSnakeCase(item.brand || brand),
				quantity: item.amount && item.amount > 0 ? item.amount?.toString() : amount?.toString() || "1",
			};
		});
	}

	constructCustomizeProductDataLayer(name: string, item: ProductVariation): CustomizeProduct {
		return {
			event: GaEvent.CustomizeProduct,
			item_id: (item as { sku: number | string }).sku.toString(),
			item_name: this.toSnakeCase(name),
			contract_duration: item.duration?.toString() || null,
			customer: this.constructCustomerData(this.userProfile),
		};
	}

	constructCheckoutData(stage: number, name: string, totalSteps: number): CheckoutData {
		return {
			checkout_stage: stage.toString() || null,
			checkout_stage_name: name || null,
			checkout_stage_total_steps: totalSteps.toString() || null,
		};
	}

	constructCustomerData(currentUser: CadacUserProfileViewModel | null): CustomerData {
		return {
			login_state: currentUser?.email ? "true" : "false",
			user_id: currentUser?.email ? currentUser.cadacAccountId : null,
			user_email_hashed: currentUser?.emailHash ? currentUser.emailHash : null,
			user_email: currentUser?.email ? currentUser.email : null,
			traffic: this.userSource,
		};
	}

	constructGenerateLeadDataLayer(currentUser: CadacUserProfileViewModel, form: FormData): GenerateLead {
		return {
			event: GaEvent.GenerateLead,
			customer: this.constructCustomerData(currentUser),
			form_title: this.toSnakeCase(form.form_title),
			form_type: this.toSnakeCase(form.form_type),
		};
	}

	trackSearch(searchString: string): void {
		const dataLayer = {
			event: GaEvent.Search,
			search_term: searchString,
			customer: this.constructCustomerData(this.userProfile),
		};
		pushToGaDataLayer.search(dataLayer);
	}

	/**
	 * Wrapper object to construct the data layer with correct types
	 */
	setDataLayer = {
		viewItem: (
			value: string | undefined,
			items: ItemsViewItemModel[],
			amount?: string | null,
			brand?: string,
			name?: string,
			category?: string
		): void =>
			pushToGaDataLayer.viewItem(
				this.constructECommerceDataLayer(GaEvent.ViewItem, value, items, amount, brand, name, category)
			),
		addToCart: (
			value: string | undefined,
			items: ItemsViewItemModel[],
			amount?: string | null,
			brand?: string,
			name?: string | null,
			category?: string | null
		): void =>
			pushToGaDataLayer.addToCart(
				this.constructECommerceDataLayer(GaEvent.AddToCart, value, items, amount, brand, name, category)
			),
		removeFromCart: (
			value: string | undefined,
			items: ItemsViewItemModel[],
			amount?: string | null,
			brand?: string
		): void =>
			pushToGaDataLayer.removeFromCart(
				this.constructECommerceDataLayer(GaEvent.RemoveFromCart, value, items, amount, brand)
			),
		viewCart: (eCommerceDataLayer: ViewItem, checkoutDataLayer: CheckoutData): void =>
			pushToGaDataLayer.viewCart(merge(eCommerceDataLayer, checkoutDataLayer)),
		beginCheckout: (eCommerceDataLayer: ViewItem, checkoutDataLayer: CheckoutData, orderDataDataLayer): void =>
			pushToGaDataLayer.beginCheckout(merge(eCommerceDataLayer, checkoutDataLayer, orderDataDataLayer)),
		checkoutStepEvents: (
			eCommerceDataLayer: ViewItem,
			checkoutDataLayer: CheckoutData,
			orderDataDataLayer
		): void =>
			pushToGaDataLayer.beginCheckout(merge(eCommerceDataLayer, checkoutDataLayer, orderDataDataLayer)),
		purchase: (eCommerceDataLayer: ViewItem, orderDataDataLayer): void =>
			pushToGaDataLayer.purchase(merge(eCommerceDataLayer, orderDataDataLayer)),
		loginEvent: (customer: CadacUserProfileViewModel | null): void => {
			if (customer)
				pushToGaDataLayer.loginEvent({
					event: GaEvent.LoginEvent,
					customer: this.constructCustomerData(customer),
				});
		},
		accountVerified: (customer: CadacUserProfileViewModel | null): void => {
			if (customer)
				pushToGaDataLayer.accountVerified({
					event: GaEvent.AccountVerified,
					customer: this.constructCustomerData(customer),
				});
		},
		customizeProduct: (name: string, item: ProductVariation): void =>
			pushToGaDataLayer.customizeProduct(this.constructCustomizeProductDataLayer(name, item)),
		generateLead: (customer: CadacUserProfileViewModel, form: FormData): void => {
			pushToGaDataLayer.generateLead(this.constructGenerateLeadDataLayer(customer, form));
		},
		newsLetterSubscribe: (customer: CadacUserProfileViewModel): void => {
			pushToGaDataLayer.newsLetterSubscribe({
				event: GaEvent.NewsletterSubscribe,
				customer: this.constructCustomerData(customer),
			});
		},
		uploadQuotation: (customer: CadacUserProfileViewModel): void => {
			pushToGaDataLayer.uploadQuotation({
				event: GaEvent.UploadQuotation,
				customer: this.constructCustomerData(customer),
			});
		},
		errorEvent: (error: any): void => {
			pushToGaDataLayer.errorEvent({
				event: GaEvent.ErrorEvent,
				error_message: error.errorMessage,
				error_code: error.errorCode,
				customer: this.constructCustomerData(this.userProfile),
			});
		},
	};
}
</script>
