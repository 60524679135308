<template>
	<div class="myCadacMenu">
		<h3>{{ $t("myCadac.menuTitle") }}</h3>
		<Panel v-for="(item, i) in mainCategories" v-model:collapsed="panelCollapse[i]" :key="i" class="mb-1">
			<template #header>
				<div class="d-flex align-items-center" @click="panelCollapse[i] = !panelCollapse[i]">
					<span class="p-panel-title">{{ $t(`enum.myCadacCategory.${item.category}`) }}</span>
					<i
						class="p-panel-header-icon"
						:class="panelCollapse[i] ? 'pi pi-chevron-left' : 'pi pi-chevron-down'"
					></i>
				</div>
			</template>
			<ul class="mycadac-menu-items">
				<li v-for="menuItem in item.items" :key="menuItem" class="" :class="{ active: menuItem.isActive }">
					<a :href="menuItem.url" :title="menuItem.title"> {{ menuItem.label }}</a>
				</li>
			</ul>
		</Panel>
		<Divider />
		<ul v-if="miscCategory" class="mycadac-menu-items">
			<li
				v-for="menuItem in miscCategory.items"
				:key="menuItem"
				class=""
				:class="{ active: menuItem.isActive }"
			>
				<a :href="menuItem.url" :title="menuItem.title"> {{ menuItem.label }}</a>
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { MyCadacMenuItemViewModel } from "@/types/models/menu/myCadacMenuItemViewModel";
import { MyCadacCategory } from "@/types/enum/myCadacCategory";
import Divider from "primevue/divider";

@Component({ components: { Divider } })
export default class MyCadacMenu extends BaseComponent {
	@Prop({ type: Object as PropType<MyCadacMenuItemViewModel[]>, required: true, default: [] })
	menuItems!: MyCadacMenuItemViewModel[];
	panelCollapse: { [index: number]: boolean } = {};

	mounted(): void {
		this.mainCategories.forEach((_x, i) => {
			this.panelCollapse[i] = this.isMobile as any;
		});
	}

	get mainCategories() {
		return this.menuItems.filter((x) => x.category !== MyCadacCategory.Miscellaneous);
	}

	get miscCategory() {
		return this.menuItems.find((x) => x.category === MyCadacCategory.Miscellaneous);
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-panel) {
	.p-panel-content {
		padding: 0.5rem 0;
	}

	.p-panel-header {
		border-bottom: none;
		padding-bottom: 0;
		justify-content: flex-start;
		cursor: pointer;

		.p-panel-title {
			margin-right: 0.5rem;
			font-weight: 300;
			font-size: 1.25rem;
			color: var(--text-color-secondary);
		}

		.p-panel-header-icon {
			width: 0.9rem;
			height: 0.9rem;
			font-size: 0.8rem;
			margin-top: 0.25rem;
		}
	}
}

.mycadac-menu-items {
	margin-bottom: 0;

	li {
		a {
			font-size: 1.25rem;
			font-weight: 400;
			color: var(--text-color);
			position: relative;
			cursor: pointer;
			text-decoration: none;

			&:hover {
				color: var(--secondary-hover-color);
			}
		}
	}

	li.active {
		a {
			font-weight: 500;
			color: var(--primary-color);

			&:hover {
				color: var(--primary-color);
			}
		}
	}
}

.myCadacMenu {
	position: sticky;
	top: calc(1rem + var(--navbarHeightInPx));
	margin-bottom: 2rem;
}
</style>
