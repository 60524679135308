<template>
	<div class="hero-search-bar">
		<Dropdown
			v-if="vm.searchCategoryOptions?.length"
			v-model="searchCategory"
			append-to="self"
			class="search-category-dropdown mr-1"
			option-label="label"
			option-value="category"
			:options="vm.searchCategoryOptions"
			scroll-height="300px"
		>
		</Dropdown>
		<AutoComplete
			append-to="self"
			class="search-input"
			:model-value="searchString"
			option-label="name"
			panel-class="search-panel"
			:placeholder="vm.searchPlaceholder || $t('menu.search_placeholder')"
			scroll-height="600px"
			:suggestions="searchResults"
			@blur="trackSearch(searchString)"
			@complete="onSearch"
			@item-select="onSearchResultSelected"
			@update:modelValue="updateSearchstring"
		>
			<template #header="slotProps">
				<div class="pt-2 pl-2">
					<p class="mb-0">
						<i18n-t keypath="menu.searchResultHeader">
							<template #query>
								<b>{{ slotProps.value.name ?? slotProps.value }}</b>
							</template>
							<template #count>
								<span v-if="!hasNoResults">
									{{ searchResult?.totalResults }}
								</span>
							</template>
						</i18n-t>
					</p>
				</div>
			</template>
			<template #option="slotProps">
				<div
					v-if="!hasNoResults"
					class="d-flex justify-content-start flex-row px-2 py-1"
					:title="slotProps.option.intro || slotProps.option.name"
				>
					<img
						v-if="slotProps.option.hasIconUrl"
						v-lazy="slotProps.option.iconUrl"
						alt="Icon"
						height="28"
						width="28"
					/>

					<Badge v-else :value="slotProps.option.type.charAt(0)"></Badge>

					<div class="ml-2">
						{{ slotProps.option.name }}
					</div>
					<div>
						<Chip class="p-chip-secondary ml-2">{{ slotProps.option.type }}</Chip>
					</div>
				</div>
			</template>
			<template #footer>
				<div v-if="hasNoResults" class="px-2 pb-2">
					{{ $t("menu.noSearchResults") }}
				</div>
				<LinkButton
					v-if="showDisplayAllResultsButton"
					class="p-button-text p-button-secondary ml-2 mb-1"
					:label="$t('menu.button.showAllSearchResults')"
					:url="searchPageUrl"
				/>
				<!--				<div class="pl-2 pb-2">-->
				<!--					<p class="text-muted">{{ $t("menu.searchResultFooter") }}</p>-->
				<!--					<div>-->
				<!--						<Button-->
				<!--							v-for="option in relatedSearches"-->
				<!--							:key="option"-->
				<!--							:label="option"-->
				<!--							class="p-button-sm p-button-outlined p-button-rounded mr-2"-->
				<!--							@click.stop="onRelatedSearchTermSelected($event, option)"-->
				<!--						/>-->
				<!--					</div>-->
				<!--				</div>-->
			</template>
		</AutoComplete>
		<div v-if="!vm.hideAdvancedSearchButton && !isSmallScreen" class="my-auto">
			<LinkButton
				class="p-button-link p-button-primary ml-2"
				:label="$t('common.advancedSearch')"
				:title="$t('common.advancedSearch')"
				:url="searchPageUrl"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import AutoComplete, { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { SearchCategory } from "@/types/enum/searchCategory";
import { SearchOptionViewModel } from "@/types/models/search/searchOption";
import { SearchResultItemViewModel } from "@/types/models/search/searchResultItem";
import { SearchResultViewModel } from "@/types/models/search/searchResult";
import { isEmpty } from "lodash";

export interface IHeroSearchBar {
	searchCategoryOptions: SearchOptionViewModel[];
	searchPlaceholder: string;
	defaultSearchCategory: SearchCategory;
	hideAdvancedSearchButton: boolean;
}

@Component({ components: { OverlayPanel, Dropdown, AutoComplete, Chip } })
export default class HeroSearchBar extends BaseComponent {
	@Prop({ type: Object as PropType<IHeroSearchBar>, required: true, default: {} }) vm!: IHeroSearchBar;

	relatedSearches: string[] = [];
	searchResult!: SearchResultViewModel;
	searchCategory!: SearchCategory;
	searchString = "";

	created(): void {
		this.relatedSearches = ["Revit 2021", "Revit Training", "Revit Expert"];
		this.searchCategory = this.vm.defaultSearchCategory || SearchCategory.all;
	}

	get searchResults(): SearchResultItemViewModel[] {
		return this.searchResult?.items || [];
	}

	get showDisplayAllResultsButton(): boolean {
		return (
			!this.hasNoResults && this.searchResults.length < this.searchResult.totalResults && !!this.searchString
		);
	}

	get hasNoResults(): boolean {
		return this.searchResult.totalResults < 1;
	}

	get searchPageUrl(): string {
		const queryParams = new URLSearchParams("");
		if (this.searchString) queryParams.append("query", this.searchString);
		queryParams.append("category", this.searchCategory.toString());
		queryParams.append("allowRedirect", "true");
		const url = new URL(`${window.location.origin}/${this.layoutStore.vm.searchPageUrl}`);
		url.search = queryParams.toString();
		return url.toString();
	}

	onSearch(event: AutoCompleteCompleteEvent): void {
		const queryParams = new URLSearchParams("");
		queryParams.append("query", event.query);
		queryParams.append("category", this.searchCategory.toString());
		queryParams.append("allowRedirect", "true");
		const url = new URL(`${window.location.origin}/api/${this.layoutStore.vm.localizationPrefix}/search/`);
		url.search = queryParams.toString();
		this.axios.get<SearchResultViewModel>(url.toString(), { requestId: "heroSearch" }).then((x) => {
			this.searchResult = x.data;
			// If there are no results, add an empty item to the itemlist as a hack to show the autocomplete result dropdown anyway.
			if (this.hasNoResults) {
				this.searchResult.items.push({} as any);
			}
		});
	}

	// getRelatedSearches(): void {
	// 	this.axios.get(`/find_v2/_didyoumean?query=${encodeURIComponent(this.searchString)}&size=5`).then((res) => {
	// 		console.log(res);
	// 	});
	// }

	onSearchResultSelected(event: AutoCompleteItemSelectEvent): void {
		this.trackSearch(this.searchString);
		const item = event.value as SearchResultItemViewModel;
		if (!isEmpty(item)) this.openUrl(item.pageUrl);
	}

	onRelatedSearchTermSelected(event: InputEvent, option: string): void {
		this.searchString = option;
		this.onSearch({ originalEvent: event, query: option });
	}

	updateSearchstring(str: any): void {
		if (typeof str === "string") this.searchString = str;
	}
}
</script>

<style scoped lang="scss">
.hero-search-bar {
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 3.5rem;

	.search-category-dropdown {
		display: flex;
		flex: 1;
		align-items: center;
		max-width: clamp(150px, 20%, 225px);

		::v-deep(.p-inputtext) {
			padding: 0.5rem 1rem;
		}
	}

	.search-input {
		display: flex;
		flex: 3;

		::v-deep(.p-inputtext) {
			padding: 0.5rem 1rem;
		}
	}
}

::v-deep(.p-autocomplete-item) {
	white-space: initial !important;
}
</style>
