import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdfd9a98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "mb-1" }
const _hoisted_4 = {
  key: 0,
  class: "lead"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductPageContentSection = _resolveComponent("ProductPageContentSection")!
  const _component_ProductOverviewItemCard = _resolveComponent("ProductOverviewItemCard")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ProductPageSummary = _resolveComponent("ProductPageSummary")!
  const _component_ProductLayout = _resolveComponent("ProductLayout")!
  const _component_ProductAddedModal = _resolveComponent("ProductAddedModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ProductLayout, { vm: _ctx.vm }, {
      summary: _withCtx(() => [
        _createVNode(_component_ProductPageSummary, {
          "is-product-available": false,
          "show-support-card": !_ctx.isMobile
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, { class: "product-config-cta-card" }, {
              content: _withCtx(() => [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.vm.configurationTitle), 1),
                (!_ctx.isMobile)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.vm.configurationSubtitle), 1))
                  : _createCommentVNode("", true),
                (_ctx.vm.formDetailPageUrl)
                  ? (_openBlock(), _createBlock(_component_LinkButton, {
                      key: 1,
                      class: "p-button-success p-button-arrow p-button-raised mt-0 mt-sm-2",
                      label: _ctx.vm.configurationCta,
                      url: _ctx.vm.formDetailPageUrl
                    }, null, 8, ["label", "url"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["show-support-card"])
      ]),
      default: _withCtx(() => [
        (_ctx.$slots.blocks)
          ? (_openBlock(), _createBlock(_component_ProductPageContentSection, {
              key: 0,
              type: 'media'
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "blocks", {}, undefined, true)
              ]),
              _: 3
            }))
          : _createCommentVNode("", true),
        (_ctx.vm.relatedUpSellProducts?.length)
          ? (_openBlock(), _createBlock(_component_ProductPageContentSection, {
              key: 1,
              type: "upsell"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("product.titleUpSell")), 1),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.relatedUpSellProducts, (product) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: product,
                      class: "col-md-6"
                    }, [
                      _createVNode(_component_ProductOverviewItemCard, {
                        item: product,
                        layout: "grid"
                      }, null, 8, ["item"])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["vm"]),
    (_ctx.cartStore.showProductAddedModal)
      ? (_openBlock(), _createBlock(_component_ProductAddedModal, {
          key: 0,
          vm: _ctx.productAddedVm
        }, null, 8, ["vm"]))
      : _createCommentVNode("", true)
  ], 64))
}